import React from "react";
import Hammer from "hammerjs";
import { AnimatePresence, motion } from "motion/react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import NextPrevNavigationButton from "../NextPrevNavigationButton/NextPrevNavigationButton";

export enum TransitionDirection {
  Next = "next",
  Prev = "prev"
}

const SWIPE_MINIMUM_OFFSET = 75;
const SWIPE_NEXT = 2; // Internal number of HammerJs
const SWIPE_PREV = 4; // Internal number of HammerJs

interface Props extends Component {
  prevButton?: React.ReactElement<React.ComponentProps<typeof NextPrevNavigationButton>>;
  nextButton?: React.ReactElement<React.ComponentProps<typeof NextPrevNavigationButton>>;
  onNext?: () => void;
  onPrev?: () => void;
  transitionDirection?: TransitionDirection;
  children: React.ReactNode;
}

class SwipeableContent extends React.Component<Props, EmptyObject> {
  public static defaultProps: Partial<Props> = {
    onNext: () => undefined,
    onPrev: () => undefined
  };

  private rootRef = React.createRef<HTMLDivElement>();
  private hammer: any;

  public componentDidMount() {
    this.hammer =
      this.rootRef.current &&
      new Hammer(this.rootRef.current).on("swipe", event => {
        const delta = event.deltaX;
        const direction = event.offsetDirection;
        if (!(delta < SWIPE_MINIMUM_OFFSET && delta > -SWIPE_MINIMUM_OFFSET)) {
          if (direction === SWIPE_NEXT) {
            this.props.onNext?.();
          } else if (direction === SWIPE_PREV) {
            this.props.onPrev?.();
          }
        }
      });
  }

  public componentWillUnmount() {
    this.hammer.destroy();
  }

  public render() {
    const { classNames = [], children, transitionDirection, prevButton = null, nextButton = null } = this.props;

    return (
      <div className={cn("SwipeableContent", [], classNames)} ref={this.rootRef}>
        <AnimatePresence>
          {React.Children.map(
            children,
            child =>
              child && (
                <motion.div
                  initial={{ opacity: 0, x: transitionDirection === "next" ? 100 : -100 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.25 } }}
                  exit={{ opacity: 0, x: transitionDirection === "next" ? -100 : 100 }}
                  transition={{ style: "tween" }}
                  className="SwipeableContent__content"
                >
                  <div className="SwipeableContent__children">{child}</div>
                </motion.div>
              )
          )}
        </AnimatePresence>
        {prevButton && <div className="SwipeableContent__prev">{prevButton}</div>}
        {nextButton && <div className="SwipeableContent__next">{nextButton}</div>}
      </div>
    );
  }
}

export default SwipeableContent;
