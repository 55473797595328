import { getPossibleValues as getPossibleValuesCommons } from "../../../commons/libs/content-service";
import { UpstreamItemStatus } from "../../../commons/libs/externals/veloconnect-proxy";
import { LabeledValue } from "../../../commons/libs/map-possible-values";
import { FilterKey, ProductFilter, ProductFilterValues } from "../../../commons/specs/filters";
import { ProductKey, ProductSpecKey, ProductType } from "../../../commons/specs/product";
import { AutomaticAssortmentAvailabilityState } from "../../../commons/types/availability";
import { Currency } from "../../../commons/types/currency";
import { AssortmentPriceSettings } from "../../../commons/types/settings";

// TODO: Temporary solution / remove when possible
// Custom implementation of `getPossibleValues` that filters "unknown" automatic assortment
// availability out of possible values for client only.
// Main implementation (for usage in services) remains in commons/libs/content-service.ts
export async function getPossibleValues<Type extends ProductType>(
  specKeys: (FilterKey<Type> | ProductKey)[],
  currency: Currency,
  assortmentPriceSettings: AssortmentPriceSettings,
  filter?: ProductFilter[]
): Promise<ProductFilterValues<Type>> {
  return getPossibleValuesCommons(specKeys, currency, assortmentPriceSettings, filter).then(possibleValues => ({
    ...possibleValues,
    [ProductSpecKey.AutomaticAssortment]: (possibleValues[ProductSpecKey.AutomaticAssortment] ?? []).filter(
      (filter: LabeledValue) => filter.value !== AutomaticAssortmentAvailabilityState.Unknown
    ),
    [ProductSpecKey.VeloconnectAssortment]: (possibleValues[ProductSpecKey.VeloconnectAssortment] ?? []).filter(
      (filter: LabeledValue) => filter.value !== UpstreamItemStatus.NOT_FOUND
    )
  }));
}
