import React from "react";
import { noop } from "lodash";
import { AnimatePresence, AnimationProps, motion } from "motion/react";
import { Portal } from "react-portal";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

export type Position = "center" | "top" | "bottom" | "right" | "top-right";
export type Animation = "slide-in-left" | "fade-in";
type AnimationVariant = Record<
  Animation,
  AnimationProps["initial"] | AnimationProps["animate"] | AnimationProps["exit"]
>;

const renderProps = (element: Props["children"], isOpen: boolean, onClose: () => void) =>
  typeof element === "function" ? element(isOpen, onClose) : element;

interface Props extends Component {
  children: React.ReactNode | ((isOpen: boolean, onClose: () => void) => React.ReactNode);
  rootNode?: HTMLElement;
  position?: Position;
  hideBackground?: boolean;
  isOpen?: boolean;
  keyboardOpen?: boolean;
  onClose?: () => void;
  /**
   * fade-in is used for a common modals
   * slide-in-left is used for help modals which are sliding in from the right side of the screen
   */
  animation?: Animation;
  animationDuration?: number;
}

const ModalContainer = ({
  classNames = [],
  children,
  rootNode,
  position = "center",
  hideBackground = false,
  isOpen = false,
  keyboardOpen = false,
  onClose = noop,
  animation = "fade-in",
  animationDuration = 0.3
}: Props) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);

  const contentAnimationInitialAndExit: AnimationVariant = {
    ["slide-in-left"]: {
      x: "100%"
    },
    ["fade-in"]: {
      opacity: 0,
      scale: 0.8
    }
  };
  const contentAnimationAnimate: AnimationVariant = {
    ["slide-in-left"]: {
      x: 0
    },
    ["fade-in"]: {
      opacity: 1,
      scale: 1
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal node={rootNode}>
          <div
            className={cn(
              "ModalContainer",
              [{ [`position-${position}`]: position, hideBackground, keyboardOpen }],
              classNames
            )}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: animationDuration }}
              ref={nodeRef}
              className="ModalContainer__background"
              onClick={onClose}
            />
            <motion.div
              initial={contentAnimationInitialAndExit[animation] as AnimationProps["initial"]}
              animate={contentAnimationAnimate[animation] as AnimationProps["animate"]}
              exit={contentAnimationInitialAndExit[animation] as AnimationProps["exit"]}
              transition={{
                ease: "easeOut",
                duration: animationDuration
              }}
              className="ModalContainer__content"
            >
              {renderProps(children, isOpen, onClose)}
            </motion.div>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default ModalContainer;
