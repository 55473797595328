import React from "react";
import { AnimatePresence, motion } from "motion/react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  open: boolean;
}

const TabBarOverlayTransition = ({ classNames = [], children, open }: Props) => {
  const nodeRef = React.useRef<HTMLDivElement>(null);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: 0, zIndex: 1 }}
          exit={{ y: "100%" }}
          transition={{ style: "tween", ease: "easeOut" }}
          ref={nodeRef}
          className={cn("TabBarOverlayTransition", [], classNames)}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default TabBarOverlayTransition;
