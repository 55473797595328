import React from "react";
import { noop } from "lodash";
import { AnimatePresence, AnimationProps, motion, Variant as MotionVariant } from "motion/react";

import { Component } from "../../../../commons/types/component";
import config from "../../config";
import cn from "../../libs/class-name";

const Variant = {
  AsideClosed: "AsideClosed",
  AsideOpen: "AsideOpen",
  TabBarClosed: "TabBarClosed",
  TabBarOpen: "TabBarOpen"
} as const;

type VariantKey = (typeof Variant)[keyof typeof Variant];
type Variants = Partial<Record<VariantKey, MotionVariant>>;

const transition: AnimationProps["transition"] = {
  type: "tween",
  ease: "easeOut"
};

const ASIDE_CONTENT_WIDTH = "65vw";
const ASIDE_OVERLAY_WIDTH = `calc(100vw - ${ASIDE_CONTENT_WIDTH})`;

const MainContent = ({ children, isAsideOpen }: { children: React.ReactNode; isAsideOpen: boolean }) => {
  const variants: Variants = {
    AsideClosed: { x: 0 },
    AsideOpen: { x: `-${ASIDE_CONTENT_WIDTH}` }
  };

  return (
    <motion.div
      className="TabBarPageLayout__mainContent"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={isAsideOpen ? Variant.AsideOpen : Variant.AsideClosed}
    >
      {children}
    </motion.div>
  );
};

const TabBarNav = ({ children, isAsideOpen }: { children: React.ReactNode; isAsideOpen: boolean }) => {
  const variants: Variants = {
    AsideClosed: { y: 0 },
    AsideOpen: { y: "100%" }
  };

  return (
    <motion.nav
      className="TabBarPageLayout__tabBar"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={isAsideOpen ? Variant.AsideOpen : Variant.AsideClosed}
    >
      {children}
    </motion.nav>
  );
};

const TabBarOverlay = ({ tabBarOverlay, onClose }: { tabBarOverlay: React.ReactNode; onClose: () => void }) => {
  const variants: Variants = {
    TabBarClosed: { opacity: 0 },
    TabBarOpen: { opacity: 1 }
  };

  return (
    <motion.section
      className="TabBarPageLayout__tabBarOverlay"
      variants={variants}
      transition={transition}
      initial={Variant.TabBarClosed}
      animate={Variant.TabBarOpen}
      exit={Variant.TabBarClosed}
      onClick={event => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      {tabBarOverlay}
    </motion.section>
  );
};

const AsideContent = ({
  children,
  isAsideOpen,
  onAsideAnimationStarted,
  onAsideAnimationCompleted
}: {
  children: React.ReactNode;
  isAsideOpen: boolean;
  onAsideAnimationStarted: Props["onAsideAnimationStarted"];
  onAsideAnimationCompleted: Props["onAsideAnimationCompleted"];
}) => {
  const variants: Variants = {
    AsideClosed: { x: ASIDE_CONTENT_WIDTH, boxShadow: "0 0 0 var(--color-shadow-medium)" },
    AsideOpen: { x: 0, boxShadow: "-5px 0px 20px 0px var(--color-shadow-medium)" }
  };

  return (
    <motion.aside
      className="TabBarPageLayout__asideContent"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={isAsideOpen ? Variant.AsideOpen : Variant.AsideClosed}
      style={{ width: ASIDE_CONTENT_WIDTH }}
      onAnimationComplete={onAsideAnimationCompleted}
      onAnimationStart={onAsideAnimationStarted}
    >
      {children}
    </motion.aside>
  );
};

const AsideOverlay = ({ children }: { children: React.ReactNode }) => {
  const variants: Variants = {
    AsideClosed: {
      backgroundColor: "var(--color-util-transparent)",
      transition: {
        ...transition,
        ease: "circOut",
        duration: config.shared.transitionsDurations.fast,
        delay: 0
      }
    },
    AsideOpen: {
      backgroundColor: "var(--color-util-black-alpha-10)",
      transition: {
        ...transition,
        ease: "circOut",
        duration: config.shared.transitionsDurations.default,
        delay: config.shared.transitionsDurations.default
      }
    }
  };

  return (
    <motion.div
      className="TabBarPageLayout__asideOverlay"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={Variant.AsideOpen}
      exit={Variant.AsideClosed}
      style={{ width: ASIDE_OVERLAY_WIDTH }}
    >
      {children}
    </motion.div>
  );
};

const AsideOverlayCloseButton = ({ children }: { children: React.ReactNode }) => {
  const variants: Variants = {
    AsideClosed: { x: "-200%" },
    AsideOpen: { x: 0 }
  };

  return (
    <motion.div
      className="TabBarPageLayout__asideOverlaySlot TabBarPageLayout__asideOverlaySlot--closeButton"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={Variant.AsideOpen}
      exit={Variant.AsideClosed}
    >
      {children}
    </motion.div>
  );
};

const AsideProductTitle = ({ children }: { children: React.ReactNode }) => {
  const variants: Variants = {
    AsideClosed: { x: "-200%" },
    AsideOpen: { x: 0 }
  };

  return (
    <motion.div
      className="TabBarPageLayout__asideProductTitle"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={Variant.AsideOpen}
      exit={Variant.AsideClosed}
    >
      {children}
    </motion.div>
  );
};

interface Props extends Component {
  children: React.ReactNode;

  // TabBar
  tabBar: React.ReactNode;
  tabBarOverlay: React.ReactNode;
  isTabBarOverlayOpen?: boolean;
  onCloseTabBarOverlay?: () => void;

  // Aside
  aside?: React.ReactNode;
  asideCloseButton?: React.ReactNode;
  asideProductTitle?: React.ReactNode;
  isAsideOpen?: boolean;
  onAsideAnimationStarted?: () => void;
  onAsideAnimationCompleted?: () => void;
}

const TabBarPageLayout = ({
  classNames = [],
  children: mainContent,

  // TabBar
  tabBar,
  tabBarOverlay,
  isTabBarOverlayOpen = false,
  onCloseTabBarOverlay = noop,

  // Aside
  aside: asideContent,
  asideCloseButton,
  asideProductTitle,
  isAsideOpen = false,
  onAsideAnimationStarted = noop,
  onAsideAnimationCompleted = noop
}: Props) => {
  return (
    <section className={cn("TabBarPageLayout", [{ isAsideOpen }], classNames)}>
      <MainContent isAsideOpen={isAsideOpen}>{mainContent}</MainContent>
      <TabBarNav isAsideOpen={isAsideOpen}>{tabBar}</TabBarNav>
      {!!asideContent && (
        <AsideContent
          isAsideOpen={isAsideOpen}
          onAsideAnimationStarted={onAsideAnimationStarted}
          onAsideAnimationCompleted={onAsideAnimationCompleted}
        >
          {asideContent}
        </AsideContent>
      )}

      <AnimatePresence>
        {isAsideOpen ? (
          <AsideOverlay key="aside-overlay">
            {isAsideOpen && asideCloseButton && (
              <AsideOverlayCloseButton key="aside-overlay-close-button">{asideCloseButton}</AsideOverlayCloseButton>
            )}
            {isAsideOpen && asideProductTitle && (
              <AsideProductTitle key="aside-overlay-product-title">{asideProductTitle}</AsideProductTitle>
            )}
          </AsideOverlay>
        ) : null}

        {isTabBarOverlayOpen ? (
          <TabBarOverlay key="tab-bar-overlay" tabBarOverlay={tabBarOverlay} onClose={onCloseTabBarOverlay} />
        ) : null}
      </AnimatePresence>
    </section>
  );
};

export default TabBarPageLayout;
