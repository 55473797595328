/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * WaWi-Proxy
 * Zentrale Abstraktionsschicht für X WaWis
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@bike.center
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

//#region Runtime
import FormData from "form-data";
import fetch, { Blob, RequestCredentials, RequestInit, Response } from "node-fetch";

export type FetchAPI = (url: string, init: RequestInit) => any;
/**
 * ISO Date string
 * @example 2023-02-28
 */
export type date = string;
/**
 * ISO Datetime string
 * @example 2023-02-29T12:00:00.000Z
 */
export type datetime = string;

export const BASE_PATH = "https://wawi.bike.center/api/v1".replace(/\/+$/, "");

export interface ConfigurationParameters {
  basePath?: string; // override base path
  fetchApi?: FetchAPI; // override for fetch implementation
  middleware?: Middleware[]; // middleware to apply before/after fetch requests
  queryParamsStringify?: (params: HTTPQuery) => string; // stringify function for query strings
  username?: string; // parameter for basic security
  password?: string; // parameter for basic security
  apiKey?: string | ((name: string) => string | Promise<string>); // parameter for apiKey security
  accessToken?: string | Promise<string> | ((name?: string, scopes?: string[]) => string | Promise<string>); // parameter for oauth2 security
  headers?: HTTPHeaders; //header params we want to use on every request
  credentials?: RequestCredentials; //value for the credentials param we want to use on each request
}

export class Configuration {
  constructor(private configuration: ConfigurationParameters = {}) {}

  set config(configuration: Configuration) {
    this.configuration = configuration;
  }

  get basePath(): string {
    return this.configuration.basePath != null ? this.configuration.basePath : BASE_PATH;
  }

  get fetchApi(): FetchAPI | undefined {
    return this.configuration.fetchApi;
  }

  get middleware(): Middleware[] {
    return this.configuration.middleware || [];
  }

  get queryParamsStringify(): (params: HTTPQuery) => string {
    return this.configuration.queryParamsStringify || querystring;
  }

  get username(): string | undefined {
    return this.configuration.username;
  }

  get password(): string | undefined {
    return this.configuration.password;
  }

  get apiKey(): ((name: string) => string | Promise<string>) | undefined {
    const apiKey = this.configuration.apiKey;
    if (apiKey) {
      return typeof apiKey === "function" ? apiKey : () => apiKey;
    }
    return undefined;
  }

  get accessToken(): ((name?: string, scopes?: string[]) => string | Promise<string>) | undefined {
    const accessToken = this.configuration.accessToken;
    if (accessToken) {
      return typeof accessToken === "function" ? accessToken : async () => accessToken;
    }
    return undefined;
  }

  get headers(): HTTPHeaders | undefined {
    return this.configuration.headers;
  }

  get credentials(): RequestCredentials | undefined {
    return this.configuration.credentials;
  }
}

export const DefaultConfig = new Configuration();

/**
 * This is the base class for all generated API classes.
 */
export class BaseAPI {
  private static readonly jsonRegex = /^(:?application\/json|[^;/ \t]+\/+\/[^;/ \t]+[+]json)[ \t]*(:?;.*)?$/i;
  private middleware: Middleware[];

  constructor(protected configuration = DefaultConfig) {
    this.middleware = configuration.middleware;
  }

  withMiddleware<T extends BaseAPI>(this: T, ...middlewares: Middleware[]) {
    const next = this.clone<T>();
    next.middleware = next.middleware.concat(...middlewares);
    return next;
  }

  withPreMiddleware<T extends BaseAPI>(this: T, ...preMiddlewares: Array<Middleware["pre"]>) {
    const middlewares = preMiddlewares.map(pre => ({ pre }));
    return this.withMiddleware<T>(...middlewares);
  }

  withPostMiddleware<T extends BaseAPI>(this: T, ...postMiddlewares: Array<Middleware["post"]>) {
    const middlewares = postMiddlewares.map(post => ({ post }));
    return this.withMiddleware<T>(...middlewares);
  }

  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  protected isJsonMime(mime: string | null | undefined): boolean {
    if (!mime) {
      return false;
    }
    return BaseAPI.jsonRegex.test(mime);
  }

  protected async request(context: RequestOpts, initOverrides?: RequestInit | InitOverrideFunction): Promise<Response> {
    const { url, init } = await this.createFetchParams(context, initOverrides);
    const response = await this.fetchApi(url, init);
    if (response && response.status >= 200 && response.status < 300) {
      return response;
    }
    throw new ResponseError(response, "Response returned an error code");
  }

  private async createFetchParams(context: RequestOpts, initOverrides?: RequestInit | InitOverrideFunction) {
    let url = this.configuration.basePath + context.path;
    if (context.query !== undefined && Object.keys(context.query).length !== 0) {
      // only add the querystring to the URL if there are query parameters.
      // this is done to avoid urls ending with a "?" character which buggy webservers
      // do not handle correctly sometimes.
      url += "?" + this.configuration.queryParamsStringify(context.query);
    }

    const headers = Object.assign({}, this.configuration.headers, context.headers);
    Object.keys(headers).forEach(key => (headers[key] === undefined ? delete headers[key] : {}));

    const initOverrideFn = typeof initOverrides === "function" ? initOverrides : async () => initOverrides;

    const initParams = {
      method: context.method,
      headers,
      body: context.body,
      credentials: this.configuration.credentials
    };

    const overriddenInit: RequestInit = {
      ...initParams,
      ...(await initOverrideFn({
        init: initParams,
        context
      }))
    };

    let body: any;
    if (
      isFormData(overriddenInit.body) ||
      overriddenInit.body instanceof URLSearchParams ||
      isBlob(overriddenInit.body)
    ) {
      body = overriddenInit.body;
    } else if (this.isJsonMime(headers["Content-Type"])) {
      body = JSON.stringify(overriddenInit.body);
    } else {
      body = overriddenInit.body;
    }

    const init: RequestInit = {
      ...overriddenInit,
      body
    };

    return { url, init };
  }

  private fetchApi = async (url: string, init: RequestInit) => {
    let fetchParams = { url, init };
    for (const middleware of this.middleware) {
      if (middleware.pre) {
        fetchParams =
          (await middleware.pre({
            fetch: this.fetchApi,
            ...fetchParams
          })) || fetchParams;
      }
    }
    let response: Response | undefined = undefined;
    try {
      response = await (this.configuration.fetchApi || fetch)(fetchParams.url, fetchParams.init);
    } catch (e) {
      for (const middleware of this.middleware) {
        if (middleware.onError) {
          response =
            (await middleware.onError({
              fetch: this.fetchApi,
              url: fetchParams.url,
              init: fetchParams.init,
              error: e,
              response: response ? response.clone() : undefined
            })) || response;
        }
      }
      if (response === undefined) {
        if (e instanceof Error) {
          throw new FetchError(e, "The request failed and the interceptors did not return an alternative response");
        } else {
          throw e;
        }
      }
    }
    for (const middleware of this.middleware) {
      if (middleware.post) {
        response =
          (await middleware.post({
            fetch: this.fetchApi,
            url: fetchParams.url,
            init: fetchParams.init,
            response: response?.clone()
          })) || response;
      }
    }
    return response;
  };

  /**
   * Create a shallow clone of `this` by constructing a new instance
   * and then shallow cloning data members.
   */
  private clone<T extends BaseAPI>(this: T): T {
    const constructor = this.constructor as any;
    const next = new constructor(this.configuration);
    next.middleware = this.middleware.slice();
    return next;
  }
}

function isBlob(value: any): value is Blob {
  return typeof Blob !== "undefined" && value instanceof Blob;
}

function isFormData(value: any): value is FormData {
  return typeof FormData !== "undefined" && value instanceof FormData;
}

export class ResponseError extends Error {
  override name = "ResponseError" as const;
  constructor(
    public response?: Response,
    msg?: string
  ) {
    super(msg);
  }
}

export class FetchError extends Error {
  override name = "FetchError" as const;
  constructor(
    public cause: Error,
    msg?: string
  ) {
    super(msg);
  }
}

export class RequiredError extends Error {
  override name = "RequiredError" as const;
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
  }
}

export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};

export type Json = any;
export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS" | "HEAD";
export type HTTPHeaders = { [key: string]: string };
export type HTTPQuery = {
  [key: string]:
    | string
    | number
    | null
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery;
};
export type HTTPBody = Json | FormData | URLSearchParams;
export type HTTPRequestInit = {
  headers?: HTTPHeaders;
  method: HTTPMethod;
  credentials?: RequestCredentials;
  body?: HTTPBody;
};
export type ModelPropertyNaming = "camelCase" | "snake_case" | "PascalCase" | "original";

export type InitOverrideFunction = (requestContext: {
  init: HTTPRequestInit;
  context: RequestOpts;
}) => Promise<RequestInit>;

export interface FetchParams {
  url: string;
  init: RequestInit;
}

export interface RequestOpts {
  path: string;
  method: HTTPMethod;
  headers: HTTPHeaders;
  query?: HTTPQuery;
  body?: HTTPBody;
}

export function exists(json: any, key: string) {
  const value = json[key];
  return value !== null && value !== undefined;
}

export function querystring(params: HTTPQuery, prefix: string = ""): string {
  return Object.keys(params)
    .map(key => querystringSingleKey(key, params[key], prefix))
    .filter(part => part.length > 0)
    .join("&");
}

function querystringSingleKey(
  key: string,
  value:
    | string
    | number
    | null
    | undefined
    | boolean
    | Array<string | number | null | boolean>
    | Set<string | number | null | boolean>
    | HTTPQuery,
  keyPrefix: string = ""
): string {
  const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
  if (value instanceof Array) {
    const multiValue = value
      .map(singleValue => encodeURIComponent(String(singleValue)))
      .join(`&${encodeURIComponent(fullKey)}=`);
    return `${encodeURIComponent(fullKey)}=${multiValue}`;
  }
  if (value instanceof Set) {
    const valueAsArray = Array.from(value);
    return querystringSingleKey(key, valueAsArray, keyPrefix);
  }
  if (value instanceof Date) {
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
  }
  if (value instanceof Object) {
    return querystring(value as HTTPQuery, fullKey);
  }
  return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}

export function mapValues(data: any, fn: (item: any) => any) {
  return Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: fn(data[key]) }), {});
}

export function canConsumeForm(consumes: Consume[]): boolean {
  for (const consume of consumes) {
    if ("multipart/form-data" === consume.contentType) {
      return true;
    }
  }
  return false;
}

export interface Consume {
  contentType: string;
}

export interface RequestContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
}

export interface ResponseContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
  response: Response | undefined;
}

export interface ErrorContext {
  fetch: FetchAPI;
  url: string;
  init: RequestInit;
  error: unknown;
  response?: Response;
}

export interface Middleware {
  pre?(context: RequestContext): Promise<FetchParams | void>;
  post?(context: ResponseContext): Promise<Response | void>;
  onError?(context: ErrorContext): Promise<Response | void>;
}

export interface ApiResponse<T> {
  raw: Response;
  value(): Promise<T>;
}

export interface ResponseTransformer<T> {
  (json: any): T;
}

export class JSONApiResponse<T> {
  constructor(
    public raw: Response,
    private transformer: ResponseTransformer<T> = (jsonValue: any) => jsonValue
  ) {}

  async value(): Promise<T> {
    return this.transformer(await this.raw.json());
  }
}

export class VoidApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<void> {
    return undefined;
  }
}

export class BlobApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<Blob> {
    return await this.raw.blob();
  }
}

export class TextApiResponse {
  constructor(public raw: Response) {}

  async value(): Promise<string> {
    return await this.raw.text();
  }
}

//#endregion

//#region Models
//#region BikeCenter
/**
 *
 * @export
 * @interface BikeCenter
 */
export interface BikeCenter {
  /**
   * Explicit storage names for storageIds
   * "default" can be used as fallback
   *
   */
  storageNames?: { [key: string]: string };
  defaultCurrencyCode?: CurrencyCode;
  defaultTax?: number;
  defaultImportPriceType?: ImportPriceType;
  /**
   * ID of the wawi connection
   */
  connId?: number;
  wawiType?: WawiType;
  wawiCredentials?: { [key: string]: string };
  /**
   * If set signals that this Bikecenter is part of a wawi-connection group
   *
   */
  mainConnId?: number;
  connCreationTime?: datetime;
  serviceTag: string;
  /**
   * APIKey of the bikecenter (not displayable)
   */
  apiKey: string;
  /**
   * Displays of Device can be registered
   */
  registerable: boolean;
  /**
   * Is developement device
   */
  devDevice: boolean;
  lastSuccessfulImport?: ImporterStats;
  /**
   * Identification of the storage location in a wawi-connection group
   */
  storageId?: string;
  lastInteractionTime?: datetime;
  creationTime: datetime;
}

/**
 * Check if a given object implements the BikeCenter interface.
 */
export function instanceOfBikeCenter(value: object): boolean {
  return (
    "serviceTag" in value &&
    "apiKey" in value &&
    "registerable" in value &&
    "devDevice" in value &&
    "creationTime" in value
  );
}

export function BikeCenterFromJSON(json: any): BikeCenter {
  return BikeCenterFromJSONTyped(json, false);
}

export function BikeCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BikeCenter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    storageNames: !exists(json, "storageNames") ? undefined : json["storageNames"],
    defaultCurrencyCode: !exists(json, "defaultCurrencyCode")
      ? undefined
      : CurrencyCodeFromJSON(json["defaultCurrencyCode"]),
    defaultTax: !exists(json, "defaultTax") ? undefined : json["defaultTax"],
    defaultImportPriceType: !exists(json, "defaultImportPriceType")
      ? undefined
      : ImportPriceTypeFromJSON(json["defaultImportPriceType"]),
    connId: !exists(json, "connId") ? undefined : json["connId"],
    wawiType: !exists(json, "wawiType") ? undefined : WawiTypeFromJSON(json["wawiType"]),
    wawiCredentials: !exists(json, "wawiCredentials") ? undefined : json["wawiCredentials"],
    mainConnId: !exists(json, "mainConnId") ? undefined : json["mainConnId"],
    connCreationTime: !exists(json, "connCreationTime") ? undefined : json["connCreationTime"],
    serviceTag: json["serviceTag"],
    apiKey: json["apiKey"],
    registerable: json["registerable"],
    devDevice: json["devDevice"],
    lastSuccessfulImport: !exists(json, "lastSuccessfulImport")
      ? undefined
      : ImporterStatsFromJSON(json["lastSuccessfulImport"]),
    storageId: !exists(json, "storageId") ? undefined : json["storageId"],
    lastInteractionTime: !exists(json, "lastInteractionTime") ? undefined : json["lastInteractionTime"],
    creationTime: json["creationTime"]
  };
}

export function BikeCenterToJSON(value?: BikeCenter | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    storageNames: value.storageNames,
    defaultCurrencyCode: CurrencyCodeToJSON(value.defaultCurrencyCode),
    defaultTax: value.defaultTax,
    defaultImportPriceType: ImportPriceTypeToJSON(value.defaultImportPriceType),
    connId: value.connId,
    wawiType: WawiTypeToJSON(value.wawiType),
    wawiCredentials: value.wawiCredentials,
    mainConnId: value.mainConnId,
    connCreationTime: value.connCreationTime === undefined ? undefined : value.connCreationTime,
    serviceTag: value.serviceTag,
    apiKey: value.apiKey,
    registerable: value.registerable,
    devDevice: value.devDevice,
    lastSuccessfulImport: ImporterStatsToJSON(value.lastSuccessfulImport),
    storageId: value.storageId,
    lastInteractionTime: value.lastInteractionTime === undefined ? undefined : value.lastInteractionTime,
    creationTime: value.creationTime
  };
}

//#endregion
//#region CurrencyCode
/**
 * In general the lowest denomination of the selected currency is used
 * Currency Code should be a valid ISO 4217 alphabetic code
 *
 * @export
 * @enum {string}
 */
export enum CurrencyCode {
  EUR = "EUR",
  CHF = "CHF",
  DKK = "DKK"
}

export function instanceOfCurrencyCode(value: any): boolean {
  for (const key in CurrencyCode) {
    if (Object.prototype.hasOwnProperty.call(CurrencyCode, key)) {
      if (CurrencyCode[key as keyof typeof CurrencyCode] === value) {
        return true;
      }
    }
  }
  return false;
}

export function CurrencyCodeFromJSON(json: any): CurrencyCode {
  return CurrencyCodeFromJSONTyped(json, false);
}

export function CurrencyCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyCode {
  return json as CurrencyCode;
}

export function CurrencyCodeToJSON(value?: CurrencyCode | null): any {
  return value as any;
}

//#endregion
//#region ErpAvailabilityStatus
/**
 *
 * @export
 * @enum {string}
 */
export enum ErpAvailabilityStatus {
  YES = "yes",
  NO = "no",
  ORDERABLE = "orderable"
}

export function instanceOfErpAvailabilityStatus(value: any): boolean {
  for (const key in ErpAvailabilityStatus) {
    if (Object.prototype.hasOwnProperty.call(ErpAvailabilityStatus, key)) {
      if (ErpAvailabilityStatus[key as keyof typeof ErpAvailabilityStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ErpAvailabilityStatusFromJSON(json: any): ErpAvailabilityStatus {
  return ErpAvailabilityStatusFromJSONTyped(json, false);
}

export function ErpAvailabilityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErpAvailabilityStatus {
  return json as ErpAvailabilityStatus;
}

export function ErpAvailabilityStatusToJSON(value?: ErpAvailabilityStatus | null): any {
  return value as any;
}

//#endregion
//#region ImportPriceType
/**
 *
 * @export
 * @enum {string}
 */
export enum ImportPriceType {
  NET = "net",
  GROSS = "gross"
}

export function instanceOfImportPriceType(value: any): boolean {
  for (const key in ImportPriceType) {
    if (Object.prototype.hasOwnProperty.call(ImportPriceType, key)) {
      if (ImportPriceType[key as keyof typeof ImportPriceType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ImportPriceTypeFromJSON(json: any): ImportPriceType {
  return ImportPriceTypeFromJSONTyped(json, false);
}

export function ImportPriceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportPriceType {
  return json as ImportPriceType;
}

export function ImportPriceTypeToJSON(value?: ImportPriceType | null): any {
  return value as any;
}

//#endregion
//#region ImportProcessingJob
/**
 *
 * @export
 * @interface ImportProcessingJob
 */
export interface ImportProcessingJob {
  status: JobStatus;
  connId: number;
}

/**
 * Check if a given object implements the ImportProcessingJob interface.
 */
export function instanceOfImportProcessingJob(value: object): boolean {
  return "status" in value && "connId" in value;
}

export function ImportProcessingJobFromJSON(json: any): ImportProcessingJob {
  return ImportProcessingJobFromJSONTyped(json, false);
}

export function ImportProcessingJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportProcessingJob {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: JobStatusFromJSON(json["status"]),
    connId: json["connId"]
  };
}

export function ImportProcessingJobToJSON(value?: ImportProcessingJob | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    status: JobStatusToJSON(value.status),
    connId: value.connId
  };
}

//#endregion
//#region ImportProcessingStatus
/**
 *
 * @export
 * @enum {string}
 */
export enum ImportProcessingStatus {
  RUNNING = "running",
  PAUSED = "paused"
}

export function instanceOfImportProcessingStatus(value: any): boolean {
  for (const key in ImportProcessingStatus) {
    if (Object.prototype.hasOwnProperty.call(ImportProcessingStatus, key)) {
      if (ImportProcessingStatus[key as keyof typeof ImportProcessingStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ImportProcessingStatusFromJSON(json: any): ImportProcessingStatus {
  return ImportProcessingStatusFromJSONTyped(json, false);
}

export function ImportProcessingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportProcessingStatus {
  return json as ImportProcessingStatus;
}

export function ImportProcessingStatusToJSON(value?: ImportProcessingStatus | null): any {
  return value as any;
}

//#endregion
//#region ImporterRawData
/**
 *
 * @export
 * @interface ImporterRawData
 */
export interface ImporterRawData {
  id: string;
  importStatsId: string;
  name: string;
  time: datetime;
}

/**
 * Check if a given object implements the ImporterRawData interface.
 */
export function instanceOfImporterRawData(value: object): boolean {
  return "id" in value && "importStatsId" in value && "name" in value && "time" in value;
}

export function ImporterRawDataFromJSON(json: any): ImporterRawData {
  return ImporterRawDataFromJSONTyped(json, false);
}

export function ImporterRawDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImporterRawData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    importStatsId: json["importStatsId"],
    name: json["name"],
    time: json["time"]
  };
}

export function ImporterRawDataToJSON(value?: ImporterRawData | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    id: value.id,
    importStatsId: value.importStatsId,
    name: value.name,
    time: value.time
  };
}

//#endregion
//#region ImporterStats
/**
 *
 * @export
 * @interface ImporterStats
 */
export interface ImporterStats {
  id?: string;
  importTime?: datetime;
  /**
   * deprecated use alias pauseImportTill instead
   */
  nextImportTime?: datetime;
  pauseImportTill?: datetime;
  importFinishedTime?: datetime;
  gtinImports: number;
  articleNumberImports: number;
  nameImports: number;
  notImportable: number;
  failedReason?: ImporterStatsFailedReason;
}

/**
 * Check if a given object implements the ImporterStats interface.
 */
export function instanceOfImporterStats(value: object): boolean {
  return (
    "gtinImports" in value && "articleNumberImports" in value && "nameImports" in value && "notImportable" in value
  );
}

export function ImporterStatsFromJSON(json: any): ImporterStats {
  return ImporterStatsFromJSONTyped(json, false);
}

export function ImporterStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImporterStats {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    importTime: !exists(json, "importTime") ? undefined : json["importTime"],
    nextImportTime: !exists(json, "nextImportTime") ? undefined : json["nextImportTime"],
    pauseImportTill: !exists(json, "pauseImportTill") ? undefined : json["pauseImportTill"],
    importFinishedTime: !exists(json, "importFinishedTime") ? undefined : json["importFinishedTime"],
    gtinImports: json["gtinImports"],
    articleNumberImports: json["articleNumberImports"],
    nameImports: json["nameImports"],
    notImportable: json["notImportable"],
    failedReason: !exists(json, "failedReason") ? undefined : ImporterStatsFailedReasonFromJSON(json["failedReason"])
  };
}

export function ImporterStatsToJSON(value?: ImporterStats | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    id: value.id,
    importTime: value.importTime === undefined ? undefined : value.importTime,
    nextImportTime: value.nextImportTime === undefined ? undefined : value.nextImportTime,
    pauseImportTill: value.pauseImportTill === undefined ? undefined : value.pauseImportTill,
    importFinishedTime: value.importFinishedTime === undefined ? undefined : value.importFinishedTime,
    gtinImports: value.gtinImports,
    articleNumberImports: value.articleNumberImports,
    nameImports: value.nameImports,
    notImportable: value.notImportable,
    failedReason: ImporterStatsFailedReasonToJSON(value.failedReason)
  };
}

//#endregion
//#region ImporterStatsFailedReason
/**
 *
 * @export
 * @enum {string}
 */
export enum ImporterStatsFailedReason {
  INVALID_FILES = "INVALID_FILES",
  INVALID_CONTENT = "INVALID_CONTENT",
  NO_CONTENT = "NO_CONTENT",
  NOTHING_TO_IMPORT = "NOTHING_TO_IMPORT",
  EXPORT_MIGHT_BE_IN_PROGRESS = "EXPORT_MIGHT_BE_IN_PROGRESS",
  INVALID_STORAGE_ID_IN_CONNECTION_GROUP = "INVALID_STORAGE_ID_IN_CONNECTION_GROUP",
  PRICE_NO_SAFE_INTEGER = "PRICE_NO_SAFE_INTEGER",
  INVALID_WAWI_SETTINGS = "INVALID_WAWI_SETTINGS",
  UNKNOWN = "UNKNOWN"
}

export function instanceOfImporterStatsFailedReason(value: any): boolean {
  for (const key in ImporterStatsFailedReason) {
    if (Object.prototype.hasOwnProperty.call(ImporterStatsFailedReason, key)) {
      if (ImporterStatsFailedReason[key as keyof typeof ImporterStatsFailedReason] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ImporterStatsFailedReasonFromJSON(json: any): ImporterStatsFailedReason {
  return ImporterStatsFailedReasonFromJSONTyped(json, false);
}

export function ImporterStatsFailedReasonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ImporterStatsFailedReason {
  return json as ImporterStatsFailedReason;
}

export function ImporterStatsFailedReasonToJSON(value?: ImporterStatsFailedReason | null): any {
  return value as any;
}

//#endregion
//#region JobStatus
/**
 *
 * @export
 * @enum {string}
 */
export enum JobStatus {
  WAITING = "waiting",
  ACTIVE = "active",
  PAUSED = "paused"
}

export function instanceOfJobStatus(value: any): boolean {
  for (const key in JobStatus) {
    if (Object.prototype.hasOwnProperty.call(JobStatus, key)) {
      if (JobStatus[key as keyof typeof JobStatus] === value) {
        return true;
      }
    }
  }
  return false;
}

export function JobStatusFromJSON(json: any): JobStatus {
  return JobStatusFromJSONTyped(json, false);
}

export function JobStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStatus {
  return json as JobStatus;
}

export function JobStatusToJSON(value?: JobStatus | null): any {
  return value as any;
}

//#endregion
//#region Price
/**
 *
 * @export
 * @interface Price
 */
export interface Price {
  type: PriceType;
  /**
   * Given in the lowest denomination of the selected currency
   */
  net: number;
  /**
   * Given in the lowest denomination of the selected currency
   */
  gross: number;
  /**
   * Given as percent
   */
  tax: number;
  currencyCode: CurrencyCode;
}

/**
 * Check if a given object implements the Price interface.
 */
export function instanceOfPrice(value: object): boolean {
  return "type" in value && "net" in value && "gross" in value && "tax" in value && "currencyCode" in value;
}

export function PriceFromJSON(json: any): Price {
  return PriceFromJSONTyped(json, false);
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: PriceTypeFromJSON(json["type"]),
    net: json["net"],
    gross: json["gross"],
    tax: json["tax"],
    currencyCode: CurrencyCodeFromJSON(json["currencyCode"])
  };
}

export function PriceToJSON(value?: Price | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    type: PriceTypeToJSON(value.type),
    net: value.net,
    gross: value.gross,
    tax: value.tax,
    currencyCode: CurrencyCodeToJSON(value.currencyCode)
  };
}

//#endregion
//#region PriceType
/**
 * What kind of price
 * - rrp: rrp given by wawi
 * - sales: sales price always there if price is given might be rrp or reduced or an explicit sales price
 * - reduced: reduced price if given
 *
 * @export
 * @enum {string}
 */
export enum PriceType {
  RRP = "rrp",
  SALES = "sales",
  REDUCED = "reduced"
}

export function instanceOfPriceType(value: any): boolean {
  for (const key in PriceType) {
    if (Object.prototype.hasOwnProperty.call(PriceType, key)) {
      if (PriceType[key as keyof typeof PriceType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function PriceTypeFromJSON(json: any): PriceType {
  return PriceTypeFromJSONTyped(json, false);
}

export function PriceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceType {
  return json as PriceType;
}

export function PriceTypeToJSON(value?: PriceType | null): any {
  return value as any;
}

//#endregion
//#region Prices
/**
 *
 * @export
 * @interface Prices
 */
export interface Prices {
  rrp?: PricesRrp;
  sales: PricesSales;
  reduced?: PricesReduced;
}

/**
 * Check if a given object implements the Prices interface.
 */
export function instanceOfPrices(value: object): boolean {
  return "sales" in value;
}

export function PricesFromJSON(json: any): Prices {
  return PricesFromJSONTyped(json, false);
}

export function PricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prices {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    rrp: !exists(json, "rrp") ? undefined : PricesRrpFromJSON(json["rrp"]),
    sales: PricesSalesFromJSON(json["sales"]),
    reduced: !exists(json, "reduced") ? undefined : PricesReducedFromJSON(json["reduced"])
  };
}

export function PricesToJSON(value?: Prices | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    rrp: PricesRrpToJSON(value.rrp),
    sales: PricesSalesToJSON(value.sales),
    reduced: PricesReducedToJSON(value.reduced)
  };
}

//#endregion
//#region PricesReduced
/**
 *
 * @export
 * @interface PricesReduced
 */
export interface PricesReduced {
  type: PriceType;
  /**
   * Given in the lowest denomination of the selected currency
   */
  net: number;
  /**
   * Given in the lowest denomination of the selected currency
   */
  gross: number;
  /**
   * Given as percent
   */
  tax: number;
  currencyCode: CurrencyCode;
}

/**
 * Check if a given object implements the PricesReduced interface.
 */
export function instanceOfPricesReduced(value: object): boolean {
  return "type" in value && "net" in value && "gross" in value && "tax" in value && "currencyCode" in value;
}

export function PricesReducedFromJSON(json: any): PricesReduced {
  return PricesReducedFromJSONTyped(json, false);
}

export function PricesReducedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricesReduced {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: PriceTypeFromJSON(json["type"]),
    net: json["net"],
    gross: json["gross"],
    tax: json["tax"],
    currencyCode: CurrencyCodeFromJSON(json["currencyCode"])
  };
}

export function PricesReducedToJSON(value?: PricesReduced | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    type: PriceTypeToJSON(value.type),
    net: value.net,
    gross: value.gross,
    tax: value.tax,
    currencyCode: CurrencyCodeToJSON(value.currencyCode)
  };
}

//#endregion
//#region PricesRrp
/**
 *
 * @export
 * @interface PricesRrp
 */
export interface PricesRrp {
  type: PriceType;
  /**
   * Given in the lowest denomination of the selected currency
   */
  net: number;
  /**
   * Given in the lowest denomination of the selected currency
   */
  gross: number;
  /**
   * Given as percent
   */
  tax: number;
  currencyCode: CurrencyCode;
}

/**
 * Check if a given object implements the PricesRrp interface.
 */
export function instanceOfPricesRrp(value: object): boolean {
  return "type" in value && "net" in value && "gross" in value && "tax" in value && "currencyCode" in value;
}

export function PricesRrpFromJSON(json: any): PricesRrp {
  return PricesRrpFromJSONTyped(json, false);
}

export function PricesRrpFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricesRrp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: PriceTypeFromJSON(json["type"]),
    net: json["net"],
    gross: json["gross"],
    tax: json["tax"],
    currencyCode: CurrencyCodeFromJSON(json["currencyCode"])
  };
}

export function PricesRrpToJSON(value?: PricesRrp | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    type: PriceTypeToJSON(value.type),
    net: value.net,
    gross: value.gross,
    tax: value.tax,
    currencyCode: CurrencyCodeToJSON(value.currencyCode)
  };
}

//#endregion
//#region PricesSales
/**
 *
 * @export
 * @interface PricesSales
 */
export interface PricesSales {
  type: PriceType;
  /**
   * Given in the lowest denomination of the selected currency
   */
  net: number;
  /**
   * Given in the lowest denomination of the selected currency
   */
  gross: number;
  /**
   * Given as percent
   */
  tax: number;
  currencyCode: CurrencyCode;
}

/**
 * Check if a given object implements the PricesSales interface.
 */
export function instanceOfPricesSales(value: object): boolean {
  return "type" in value && "net" in value && "gross" in value && "tax" in value && "currencyCode" in value;
}

export function PricesSalesFromJSON(json: any): PricesSales {
  return PricesSalesFromJSONTyped(json, false);
}

export function PricesSalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricesSales {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: PriceTypeFromJSON(json["type"]),
    net: json["net"],
    gross: json["gross"],
    tax: json["tax"],
    currencyCode: CurrencyCodeFromJSON(json["currencyCode"])
  };
}

export function PricesSalesToJSON(value?: PricesSales | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    type: PriceTypeToJSON(value.type),
    net: value.net,
    gross: value.gross,
    tax: value.tax,
    currencyCode: CurrencyCodeToJSON(value.currencyCode)
  };
}

//#endregion
//#region ProductAvailability
/**
 *
 * @export
 * @interface ProductAvailability
 */
export interface ProductAvailability {
  productId: ProductIdentification;
  wawiInternalId?: string;
  availabilityWaWi: Array<ProductAvailabilityWaWi>;
  availabilityBranches?: Array<ProductAvailabilityWaWi>;
  availabilityErp: ProductAvailabilityErp;
  prices?: Prices;
}

/**
 * Check if a given object implements the ProductAvailability interface.
 */
export function instanceOfProductAvailability(value: object): boolean {
  return "productId" in value && "availabilityWaWi" in value && "availabilityErp" in value;
}

export function ProductAvailabilityFromJSON(json: any): ProductAvailability {
  return ProductAvailabilityFromJSONTyped(json, false);
}

export function ProductAvailabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAvailability {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: ProductIdentificationFromJSON(json["productId"]),
    wawiInternalId: !exists(json, "wawiInternalId") ? undefined : json["wawiInternalId"],
    availabilityWaWi: (json["availabilityWaWi"] as Array<any>).map(ProductAvailabilityWaWiFromJSON),
    availabilityBranches: !exists(json, "availabilityBranches")
      ? undefined
      : (json["availabilityBranches"] as Array<any>).map(ProductAvailabilityWaWiFromJSON),
    availabilityErp: ProductAvailabilityErpFromJSON(json["availabilityErp"]),
    prices: !exists(json, "prices") ? undefined : PricesFromJSON(json["prices"])
  };
}

export function ProductAvailabilityToJSON(value?: ProductAvailability | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    productId: ProductIdentificationToJSON(value.productId),
    wawiInternalId: value.wawiInternalId,
    availabilityWaWi: (value.availabilityWaWi as Array<any>).map(ProductAvailabilityWaWiToJSON),
    availabilityBranches:
      value.availabilityBranches === undefined
        ? undefined
        : (value.availabilityBranches as Array<any>).map(ProductAvailabilityWaWiToJSON),
    availabilityErp: ProductAvailabilityErpToJSON(value.availabilityErp),
    prices: PricesToJSON(value.prices)
  };
}

//#endregion
//#region ProductAvailabilityErp
/**
 *
 * @export
 * @interface ProductAvailabilityErp
 */
export interface ProductAvailabilityErp {
  available: ErpAvailabilityStatus;
  orderableFromDate?: date;
}

/**
 * Check if a given object implements the ProductAvailabilityErp interface.
 */
export function instanceOfProductAvailabilityErp(value: object): boolean {
  return "available" in value;
}

export function ProductAvailabilityErpFromJSON(json: any): ProductAvailabilityErp {
  return ProductAvailabilityErpFromJSONTyped(json, false);
}

export function ProductAvailabilityErpFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAvailabilityErp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    available: ErpAvailabilityStatusFromJSON(json["available"]),
    orderableFromDate: !exists(json, "orderableFromDate") ? undefined : json["orderableFromDate"]
  };
}

export function ProductAvailabilityErpToJSON(value?: ProductAvailabilityErp | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    available: ErpAvailabilityStatusToJSON(value.available),
    orderableFromDate: value.orderableFromDate === undefined ? undefined : value.orderableFromDate
  };
}

//#endregion
//#region ProductAvailabilityWaWi
/**
 *
 * @export
 * @interface ProductAvailabilityWaWi
 */
export interface ProductAvailabilityWaWi {
  storageId?: string;
  /**
   * Displayname of the location resolved useing:
   *
   * 1. explicit set storage name of the requesting connection
   * 2. explicit set storage name of the source connection
   * 3. set storage name by wawi
   * 4. default storage name of the requesting connection
   * 5. default storage name of the source connection
   *
   */
  location: string;
  productCount: number;
  orderedCount?: number;
  orderedForDate?: date;
}

/**
 * Check if a given object implements the ProductAvailabilityWaWi interface.
 */
export function instanceOfProductAvailabilityWaWi(value: object): boolean {
  return "location" in value && "productCount" in value;
}

export function ProductAvailabilityWaWiFromJSON(json: any): ProductAvailabilityWaWi {
  return ProductAvailabilityWaWiFromJSONTyped(json, false);
}

export function ProductAvailabilityWaWiFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAvailabilityWaWi {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    storageId: !exists(json, "storageId") ? undefined : json["storageId"],
    location: json["location"],
    productCount: json["productCount"],
    orderedCount: !exists(json, "orderedCount") ? undefined : json["orderedCount"],
    orderedForDate: !exists(json, "orderedForDate") ? undefined : json["orderedForDate"]
  };
}

export function ProductAvailabilityWaWiToJSON(value?: ProductAvailabilityWaWi | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    storageId: value.storageId,
    location: value.location,
    productCount: value.productCount,
    orderedCount: value.orderedCount,
    orderedForDate: value.orderedForDate === undefined ? undefined : value.orderedForDate
  };
}

//#endregion
//#region ProductIdentification
/**
 * Either an gtin or productNumber and brand must be given.
 * @export
 * @interface ProductIdentification
 */
export interface ProductIdentification {
  gtin?: string;
  productNumber?: string;
  brandKey?: string;
  name?: string;
  metadata?: { [key: string]: string };
}

/**
 * Check if a given object implements the ProductIdentification interface.
 */
export function instanceOfProductIdentification(value: object): boolean {
  return true;
}

export function ProductIdentificationFromJSON(json: any): ProductIdentification {
  return ProductIdentificationFromJSONTyped(json, false);
}

export function ProductIdentificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductIdentification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    gtin: !exists(json, "gtin") ? undefined : json["gtin"],
    productNumber: !exists(json, "productNumber") ? undefined : json["productNumber"],
    brandKey: !exists(json, "brandKey") ? undefined : json["brandKey"],
    name: !exists(json, "name") ? undefined : json["name"],
    metadata: !exists(json, "metadata") ? undefined : json["metadata"]
  };
}

export function ProductIdentificationToJSON(value?: ProductIdentification | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    gtin: value.gtin,
    productNumber: value.productNumber,
    brandKey: value.brandKey,
    name: value.name,
    metadata: value.metadata
  };
}

//#endregion
//#region ProductResult
/**
 *
 * @export
 * @interface ProductResult
 */
export interface ProductResult {
  queryId: ProductIdentification;
  /**
   * true if queryId is invalid
   */
  invalid: boolean;
  result: Array<ProductAvailability>;
}

/**
 * Check if a given object implements the ProductResult interface.
 */
export function instanceOfProductResult(value: object): boolean {
  return "queryId" in value && "invalid" in value && "result" in value;
}

export function ProductResultFromJSON(json: any): ProductResult {
  return ProductResultFromJSONTyped(json, false);
}

export function ProductResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    queryId: ProductIdentificationFromJSON(json["queryId"]),
    invalid: json["invalid"],
    result: (json["result"] as Array<any>).map(ProductAvailabilityFromJSON)
  };
}

export function ProductResultToJSON(value?: ProductResult | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    queryId: ProductIdentificationToJSON(value.queryId),
    invalid: value.invalid,
    result: (value.result as Array<any>).map(ProductAvailabilityToJSON)
  };
}

//#endregion
//#region Status
/**
 *
 * @export
 * @interface Status
 */
export interface Status {
  code: number;
  message: string;
  event?: string;
  details?: { [key: string]: string };
}

/**
 * Check if a given object implements the Status interface.
 */
export function instanceOfStatus(value: object): boolean {
  return "code" in value && "message" in value;
}

export function StatusFromJSON(json: any): Status {
  return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    message: json["message"],
    event: !exists(json, "event") ? undefined : json["event"],
    details: !exists(json, "details") ? undefined : json["details"]
  };
}

export function StatusToJSON(value?: Status | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    code: value.code,
    message: value.message,
    event: value.event,
    details: value.details
  };
}

//#endregion
//#region UserEditableWaWiConnectionSettings
/**
 *
 * @export
 * @interface UserEditableWaWiConnectionSettings
 */
export interface UserEditableWaWiConnectionSettings {
  /**
   * Explicit storage names for storageIds
   * "default" can be used as fallback
   *
   */
  storageNames?: { [key: string]: string };
  defaultCurrencyCode?: CurrencyCode;
  defaultTax?: number;
  defaultImportPriceType?: ImportPriceType;
}

/**
 * Check if a given object implements the UserEditableWaWiConnectionSettings interface.
 */
export function instanceOfUserEditableWaWiConnectionSettings(value: object): boolean {
  return true;
}

export function UserEditableWaWiConnectionSettingsFromJSON(json: any): UserEditableWaWiConnectionSettings {
  return UserEditableWaWiConnectionSettingsFromJSONTyped(json, false);
}

export function UserEditableWaWiConnectionSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserEditableWaWiConnectionSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    storageNames: !exists(json, "storageNames") ? undefined : json["storageNames"],
    defaultCurrencyCode: !exists(json, "defaultCurrencyCode")
      ? undefined
      : CurrencyCodeFromJSON(json["defaultCurrencyCode"]),
    defaultTax: !exists(json, "defaultTax") ? undefined : json["defaultTax"],
    defaultImportPriceType: !exists(json, "defaultImportPriceType")
      ? undefined
      : ImportPriceTypeFromJSON(json["defaultImportPriceType"])
  };
}

export function UserEditableWaWiConnectionSettingsToJSON(value?: UserEditableWaWiConnectionSettings | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    storageNames: value.storageNames,
    defaultCurrencyCode: CurrencyCodeToJSON(value.defaultCurrencyCode),
    defaultTax: value.defaultTax,
    defaultImportPriceType: ImportPriceTypeToJSON(value.defaultImportPriceType)
  };
}

//#endregion
//#region WaWiConnection
/**
 *
 * @export
 * @interface WaWiConnection
 */
export interface WaWiConnection {
  /**
   * Explicit storage names for storageIds
   * "default" can be used as fallback
   *
   */
  storageNames?: { [key: string]: string };
  defaultCurrencyCode?: CurrencyCode;
  defaultTax?: number;
  defaultImportPriceType?: ImportPriceType;
  /**
   * ID of the wawi connection
   */
  connId?: number;
  wawiType?: WawiType;
  wawiCredentials?: { [key: string]: string };
  /**
   * If set signals that this Bikecenter is part of a wawi-connection group
   *
   */
  mainConnId?: number;
  connCreationTime?: datetime;
}

/**
 * Check if a given object implements the WaWiConnection interface.
 */
export function instanceOfWaWiConnection(value: object): boolean {
  return true;
}

export function WaWiConnectionFromJSON(json: any): WaWiConnection {
  return WaWiConnectionFromJSONTyped(json, false);
}

export function WaWiConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WaWiConnection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    storageNames: !exists(json, "storageNames") ? undefined : json["storageNames"],
    defaultCurrencyCode: !exists(json, "defaultCurrencyCode")
      ? undefined
      : CurrencyCodeFromJSON(json["defaultCurrencyCode"]),
    defaultTax: !exists(json, "defaultTax") ? undefined : json["defaultTax"],
    defaultImportPriceType: !exists(json, "defaultImportPriceType")
      ? undefined
      : ImportPriceTypeFromJSON(json["defaultImportPriceType"]),
    connId: !exists(json, "connId") ? undefined : json["connId"],
    wawiType: !exists(json, "wawiType") ? undefined : WawiTypeFromJSON(json["wawiType"]),
    wawiCredentials: !exists(json, "wawiCredentials") ? undefined : json["wawiCredentials"],
    mainConnId: !exists(json, "mainConnId") ? undefined : json["mainConnId"],
    connCreationTime: !exists(json, "connCreationTime") ? undefined : json["connCreationTime"]
  };
}

export function WaWiConnectionToJSON(value?: WaWiConnection | null): any {
  if (value === undefined || value === null) {
    return value;
  }
  return {
    storageNames: value.storageNames,
    defaultCurrencyCode: CurrencyCodeToJSON(value.defaultCurrencyCode),
    defaultTax: value.defaultTax,
    defaultImportPriceType: ImportPriceTypeToJSON(value.defaultImportPriceType),
    connId: value.connId,
    wawiType: WawiTypeToJSON(value.wawiType),
    wawiCredentials: value.wawiCredentials,
    mainConnId: value.mainConnId,
    connCreationTime: value.connCreationTime === undefined ? undefined : value.connCreationTime
  };
}

//#endregion
//#region WawiType
/**
 *
 * @export
 * @enum {string}
 */
export enum WawiType {
  DUMMY = "dummy",
  HIW = "hiw",
  TRIDATA = "tridata",
  VELODATA = "velodata",
  HIBIKE = "hibike",
  E_VENDO = "e-vendo",
  BIDEX = "bidex",
  DOIT7 = "doit7",
  RADFAK = "radfak",
  ASCEND = "ascend",
  VELOPORT = "veloport",
  CSB = "csb",
  FAMOWA = "famowa",
  INTERSPORT_SAUER = "intersport-sauer",
  INTERSPORT_AUSTRIA_COMBINED = "intersport-austria-combined",
  ZEGWW3 = "zegww3",
  ADVARICS = "advarics",
  BIKEARENA_OLTMANNS = "bikearena-oltmanns",
  SERVI_BIKES = "servi-bikes",
  BIKEDESK = "bikedesk",
  APP_ROOM_CYCLE = "app-room-cycle",
  FAHRRAD_XXL_FRANZ = "fahrrad-xxl-franz",
  ANWR_SPORTS_RETAIL_DATA_HUB = "anwr-sports-retail-data-hub",
  JTL = "jtl",
  FITSTORE_24 = "fitstore-24"
}

export function instanceOfWawiType(value: any): boolean {
  for (const key in WawiType) {
    if (Object.prototype.hasOwnProperty.call(WawiType, key)) {
      if (WawiType[key as keyof typeof WawiType] === value) {
        return true;
      }
    }
  }
  return false;
}

export function WawiTypeFromJSON(json: any): WawiType {
  return WawiTypeFromJSONTyped(json, false);
}

export function WawiTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WawiType {
  return json as WawiType;
}

export function WawiTypeToJSON(value?: WawiType | null): any {
  return value as any;
}

//#endregion
//#endregion

//#region AvailabilityApi

export interface DeleteWaWiConnectionRequest {
  serviceTag: string;
}

export interface GetAllProductsRequest {
  serviceTag: string;
  page?: number;
  pageSize?: number;
}

export interface GetProductsRequest {
  serviceTag: string;
  products: Array<ProductIdentification>;
}

export interface SetWaWiConnectionRequest {
  serviceTag: string;
  connection: BikeCenter;
}

export interface UpdateWaWiConnectionRequest {
  serviceTag: string;
  settings?: UserEditableWaWiConnectionSettings;
}

/**
 *
 */
export class AvailabilityApi extends BaseAPI {
  /**
   * Remove current WaWi-Connection from BikeCenter
   * Delete BikeCenter WaWi-Connection
   */
  async deleteWaWiConnectionRaw(
    requestParameters: DeleteWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling deleteWaWiConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/connection`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Remove current WaWi-Connection from BikeCenter
   * Delete BikeCenter WaWi-Connection
   */
  async deleteWaWiConnection(
    requestParameters: DeleteWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.deleteWaWiConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all Products availability informations paged.
   * Get Products availability informations
   */
  async getAllProductsRaw(
    requestParameters: GetAllProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ProductAvailability>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getAllProducts."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["pageSize"] = requestParameters.pageSize;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/products`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ProductAvailabilityFromJSON));
  }

  /**
   * Get all Products availability informations paged.
   * Get Products availability informations
   */
  async getAllProducts(
    requestParameters: GetAllProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<ProductAvailability>> {
    const response = await this.getAllProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the products informations of the gtin listed
   * Get Products availability informations
   */
  async getProductsRaw(
    requestParameters: GetProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ProductResult>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getProducts."
      );
    }

    if (requestParameters.products === null || requestParameters.products === undefined) {
      throw new RequiredError(
        "products",
        "Required parameter requestParameters.products was null or undefined when calling getProducts."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/products`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.products.map(ProductIdentificationToJSON)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ProductResultFromJSON));
  }

  /**
   * Get the products informations of the gtin listed
   * Get Products availability informations
   */
  async getProducts(
    requestParameters: GetProductsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<ProductResult>> {
    const response = await this.getProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Registers BikeCenter to WaWi if not already connected to a connection
   * Register WaWi Bindung to WaWiProxy
   */
  async setWaWiConnectionRaw(
    requestParameters: SetWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<BikeCenter>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling setWaWiConnection."
      );
    }

    if (requestParameters.connection === null || requestParameters.connection === undefined) {
      throw new RequiredError(
        "connection",
        "Required parameter requestParameters.connection was null or undefined when calling setWaWiConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BikeCenterToJSON(requestParameters.connection)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => BikeCenterFromJSON(jsonValue));
  }

  /**
   * Registers BikeCenter to WaWi if not already connected to a connection
   * Register WaWi Bindung to WaWiProxy
   */
  async setWaWiConnection(
    requestParameters: SetWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<BikeCenter> {
    const response = await this.setWaWiConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update user editable connection settings.
   * Update WaWi-Connection settings
   */
  async updateWaWiConnectionRaw(
    requestParameters: UpdateWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<BikeCenter>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling updateWaWiConnection."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/connection`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UserEditableWaWiConnectionSettingsToJSON(requestParameters.settings)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => BikeCenterFromJSON(jsonValue));
  }

  /**
   * Update user editable connection settings.
   * Update WaWi-Connection settings
   */
  async updateWaWiConnection(
    requestParameters: UpdateWaWiConnectionRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<BikeCenter> {
    const response = await this.updateWaWiConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region BackendApi

export interface AddBikeCenterRequest {
  serviceTag: string;
}

export interface DeleteBikeCenterRequest {
  serviceTag: string;
}

export interface GetBikeCenterRequest {
  serviceTag: string;
}

export interface SetRegisterableRequest {
  serviceTag: string;
  devdevice?: boolean;
}

export interface UpdateStorageOptionsRequest {
  serviceTag: string;
  connection: BikeCenter;
}

/**
 *
 */
export class BackendApi extends BaseAPI {
  /**
   * Adds BikeCenter to System and generates ApiKey for wawi-proxy
   * Add BikeCenter to System
   */
  async addBikeCenterRaw(
    requestParameters: AddBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<string>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling addBikeCenter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get("content-type"))) {
      return new JSONApiResponse<string>(response);
    } else {
      return new TextApiResponse(response) as any;
    }
  }

  /**
   * Adds BikeCenter to System and generates ApiKey for wawi-proxy
   * Add BikeCenter to System
   */
  async addBikeCenter(
    requestParameters: AddBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<string> {
    const response = await this.addBikeCenterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Deletes BikeCenter
   * Delete BikeCenter from System
   */
  async deleteBikeCenterRaw(
    requestParameters: DeleteBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling deleteBikeCenter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Deletes BikeCenter
   * Delete BikeCenter from System
   */
  async deleteBikeCenter(
    requestParameters: DeleteBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.deleteBikeCenterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get BikeCenter informations with the serviceTag
   * Get BikeCenter information
   */
  async getBikeCenterRaw(
    requestParameters: GetBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<BikeCenter>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getBikeCenter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => BikeCenterFromJSON(jsonValue));
  }

  /**
   * Get BikeCenter informations with the serviceTag
   * Get BikeCenter information
   */
  async getBikeCenter(
    requestParameters: GetBikeCenterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<BikeCenter> {
    const response = await this.getBikeCenterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get BikeCenter list
   * Get BikeCenter list
   */
  async getBikeCentersRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<Array<BikeCenter>>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(BikeCenterFromJSON));
  }

  /**
   * Get BikeCenter list
   * Get BikeCenter list
   */
  async getBikeCenters(initOverrides?: RequestInit | InitOverrideFunction): Promise<Array<BikeCenter>> {
    const response = await this.getBikeCentersRaw(initOverrides);
    return await response.value();
  }

  /**
   * Set BikeCenter to registerable
   * Set BikeCenter registerable
   */
  async setRegisterableRaw(
    requestParameters: SetRegisterableRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling setRegisterable."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.devdevice !== undefined) {
      queryParameters["devdevice"] = requestParameters.devdevice;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/registerable`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Set BikeCenter to registerable
   * Set BikeCenter registerable
   */
  async setRegisterable(
    requestParameters: SetRegisterableRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.setRegisterableRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Can be used to update `storageNames`, `mainConnId` and `storageId` of a bikecenter
   * Update WaWi-Connection storage options
   */
  async updateStorageOptionsRaw(
    requestParameters: UpdateStorageOptionsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<BikeCenter>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling updateStorageOptions."
      );
    }

    if (requestParameters.connection === null || requestParameters.connection === undefined) {
      throw new RequiredError(
        "connection",
        "Required parameter requestParameters.connection was null or undefined when calling updateStorageOptions."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/storage`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: BikeCenterToJSON(requestParameters.connection)
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => BikeCenterFromJSON(jsonValue));
  }

  /**
   * Can be used to update `storageNames`, `mainConnId` and `storageId` of a bikecenter
   * Update WaWi-Connection storage options
   */
  async updateStorageOptions(
    requestParameters: UpdateStorageOptionsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<BikeCenter> {
    const response = await this.updateStorageOptionsRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region ImportQueueApi

export interface ChangeImportQueueStatusRequest {
  action?: ChangeImportQueueStatusActionEnum;
}

/**
 *
 */
export class ImportQueueApi extends BaseAPI {
  /**
   * Pause or unpause the import processing
   * Change import processing status
   */
  async changeImportQueueStatusRaw(
    requestParameters: ChangeImportQueueStatusRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ImportProcessingStatus>> {
    const queryParameters: any = {};

    if (requestParameters.action !== undefined) {
      queryParameters["action"] = requestParameters.action;
    }

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/importer-queue/status`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ImportProcessingStatusFromJSON(jsonValue));
  }

  /**
   * Pause or unpause the import processing
   * Change import processing status
   */
  async changeImportQueueStatus(
    requestParameters: ChangeImportQueueStatusRequest = {},
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ImportProcessingStatus> {
    const response = await this.changeImportQueueStatusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets import jobs currently in queue
   * Get queue contents
   */
  async getImportQueueJobsRaw(
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ImportProcessingJob>>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/importer-queue`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ImportProcessingJobFromJSON));
  }

  /**
   * Gets import jobs currently in queue
   * Get queue contents
   */
  async getImportQueueJobs(initOverrides?: RequestInit | InitOverrideFunction): Promise<Array<ImportProcessingJob>> {
    const response = await this.getImportQueueJobsRaw(initOverrides);
    return await response.value();
  }

  /**
   * Import processing can be paused or unpaused
   * Get current import processing status
   */
  async getImportQueueStatusRaw(
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ImportProcessingStatus>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/importer-queue/status`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ImportProcessingStatusFromJSON(jsonValue));
  }

  /**
   * Import processing can be paused or unpaused
   * Get current import processing status
   */
  async getImportQueueStatus(initOverrides?: RequestInit | InitOverrideFunction): Promise<ImportProcessingStatus> {
    const response = await this.getImportQueueStatusRaw(initOverrides);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum ChangeImportQueueStatusActionEnum {
  PAUSE = "pause",
  PAUSE_AND_WAIT = "pauseAndWait",
  UNPAUSE = "unpause"
}

//#endregion
//#region ImporterApi

export interface GetImportRawDataRequest {
  serviceTag: string;
  importId: string;
  rawDataId: string;
}

export interface GetImporterStatRawMetaDataRequest {
  serviceTag: string;
  importId: string;
}

export interface GetImporterStatsRequest {
  serviceTag: string;
}

export interface GetLastImporterStatsRequest {
  serviceTag: string;
}

export interface GetLastSuccessfulImportRequest {
  serviceTag: string;
}

export interface TriggerImporterRequest {
  serviceTag: string;
}

/**
 *
 */
export class ImporterApi extends BaseAPI {
  /**
   * Returns content of a specific raw data object of a specific import
   * Get Raw Content
   */
  async getImportRawDataRaw(
    requestParameters: GetImportRawDataRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Blob>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getImportRawData."
      );
    }

    if (requestParameters.importId === null || requestParameters.importId === undefined) {
      throw new RequiredError(
        "importId",
        "Required parameter requestParameters.importId was null or undefined when calling getImportRawData."
      );
    }

    if (requestParameters.rawDataId === null || requestParameters.rawDataId === undefined) {
      throw new RequiredError(
        "rawDataId",
        "Required parameter requestParameters.rawDataId was null or undefined when calling getImportRawData."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer/{importId}/{rawDataId}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"importId"}}`, encodeURIComponent(String(requestParameters.importId)))
          .replace(`{${"rawDataId"}}`, encodeURIComponent(String(requestParameters.rawDataId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new BlobApiResponse(response);
  }

  /**
   * Returns content of a specific raw data object of a specific import
   * Get Raw Content
   */
  async getImportRawData(
    requestParameters: GetImportRawDataRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Blob> {
    const response = await this.getImportRawDataRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the Metadata of the raw data objects assoziated with this import. If raw data is no longer available the list is empty
   * Get Metadata of Raw Data from import
   */
  async getImporterStatRawMetaDataRaw(
    requestParameters: GetImporterStatRawMetaDataRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ImporterRawData>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getImporterStatRawMetaData."
      );
    }

    if (requestParameters.importId === null || requestParameters.importId === undefined) {
      throw new RequiredError(
        "importId",
        "Required parameter requestParameters.importId was null or undefined when calling getImporterStatRawMetaData."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer/{importId}`
          .replace(`{${"serviceTag"}}`, encodeURIComponent(String(requestParameters.serviceTag)))
          .replace(`{${"importId"}}`, encodeURIComponent(String(requestParameters.importId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ImporterRawDataFromJSON));
  }

  /**
   * Returns the Metadata of the raw data objects assoziated with this import. If raw data is no longer available the list is empty
   * Get Metadata of Raw Data from import
   */
  async getImporterStatRawMetaData(
    requestParameters: GetImporterStatRawMetaDataRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<ImporterRawData>> {
    const response = await this.getImporterStatRawMetaDataRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Stats about how the importer imports data
   * Get Importer informations
   */
  async getImporterStatsRaw(
    requestParameters: GetImporterStatsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Array<ImporterStats>>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getImporterStats."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => jsonValue.map(ImporterStatsFromJSON));
  }

  /**
   * Stats about how the importer imports data
   * Get Importer informations
   */
  async getImporterStats(
    requestParameters: GetImporterStatsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Array<ImporterStats>> {
    const response = await this.getImporterStatsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Stats about how the importer performed in last run
   * Last import
   */
  async getLastImporterStatsRaw(
    requestParameters: GetLastImporterStatsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ImporterStats>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getLastImporterStats."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer/last`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ImporterStatsFromJSON(jsonValue));
  }

  /**
   * Stats about how the importer performed in last run
   * Last import
   */
  async getLastImporterStats(
    requestParameters: GetLastImporterStatsRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ImporterStats> {
    const response = await this.getLastImporterStatsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Stats of the last successful import done by the connection
   * Last successful import
   */
  async getLastSuccessfulImportRaw(
    requestParameters: GetLastSuccessfulImportRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<ImporterStats>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling getLastSuccessfulImport."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer/lastSuccessful`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => ImporterStatsFromJSON(jsonValue));
  }

  /**
   * Stats of the last successful import done by the connection
   * Last successful import
   */
  async getLastSuccessfulImport(
    requestParameters: GetLastSuccessfulImportRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ImporterStats> {
    const response = await this.getLastSuccessfulImportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Trigger manual Importer Task
   * Start Async Importer Task
   */
  async triggerImporterRaw(
    requestParameters: TriggerImporterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<ApiResponse<Status>> {
    if (requestParameters.serviceTag === null || requestParameters.serviceTag === undefined) {
      throw new RequiredError(
        "serviceTag",
        "Required parameter requestParameters.serviceTag was null or undefined when calling triggerImporter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/center/{serviceTag}/importer`.replace(
          `{${"serviceTag"}}`,
          encodeURIComponent(String(requestParameters.serviceTag))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Trigger manual Importer Task
   * Start Async Importer Task
   */
  async triggerImporter(
    requestParameters: TriggerImporterRequest,
    initOverrides?: RequestInit | InitOverrideFunction
  ): Promise<Status> {
    const response = await this.triggerImporterRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

//#endregion
//#region PingApi

/**
 *
 */
export class PingApi extends BaseAPI {
  /**
   * Is the Service running
   * Ping Endpoint
   */
  async pingRaw(initOverrides?: RequestInit | InitOverrideFunction): Promise<ApiResponse<Status>> {
    const queryParameters: any = {};

    const headerParameters: HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/ping`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new JSONApiResponse(response, jsonValue => StatusFromJSON(jsonValue));
  }

  /**
   * Is the Service running
   * Ping Endpoint
   */
  async ping(initOverrides?: RequestInit | InitOverrideFunction): Promise<Status> {
    const response = await this.pingRaw(initOverrides);
    return await response.value();
  }
}

//#endregion
