import React from "react";
import { push } from "connected-react-router";
import { compact, omit } from "lodash";
import { useTranslation } from "react-i18next";
import { ConnectedProps } from "react-redux";

import { getAvailabilityState as getAvailabilityStateLib } from "../../../../commons/libs/availability";
import { getProduct } from "../../../../commons/libs/content-service";
import envelope from "../../../../commons/libs/externals/envelope";
import { byDefined } from "../../../../commons/libs/filter-guards";
import { formatDate, formatUnit, getSpecFormatter } from "../../../../commons/libs/formatters";
import { isProductOfType } from "../../../../commons/libs/products";
import {
  getFilterValuesForVariantsBySpecKey,
  getSingleSpecValue,
  getStandaloneProductVariants
} from "../../../../commons/libs/specs";
import { getWawiProductAvailabilty, WawiError } from "../../../../commons/libs/wawi-service";
import { getSpecConfig } from "../../../../commons/specs";
import { BicycleSpecKey, CustomBicycleFilterKey } from "../../../../commons/specs/bicycle";
import { ActiveFilters, ProductFilter } from "../../../../commons/specs/filters";
import {
  Product,
  ProductId,
  ProductKey,
  ProductSpecKey,
  ProductType,
  SpecMap,
  StandaloneProductVariant
} from "../../../../commons/specs/product";
import { AvailabilityState } from "../../../../commons/types/availability";
import { SizingResult } from "../../../../commons/types/sizing";
import { WawiErrorType, WawiProductAvailabilityVariant } from "../../../../commons/types/wawi";
import { IconMediumFrameSize } from "../../../../resources/icons";
import smartfitLogoInvertImage from "../../../../resources/images/body-sizing/smartfit-logo-invert.svg";
import smartfitLogoImage from "../../../../resources/images/body-sizing/smartfit-logo.svg";
import * as modalActions from "../../../commons/actions/modal";
import * as sessionActions from "../../../commons/actions/session";
import Availability from "../../../commons/components/Availability/Availability";
import AvailabilityLayout from "../../../commons/components/AvailabilityLayout/AvailabilityLayout";
import Table from "../../../commons/components/Table/Table";
import TableCell from "../../../commons/components/Table/TableCell";
import TableHeaderCell from "../../../commons/components/Table/TableHeaderCell";
import TableRow from "../../../commons/components/Table/TableRow";
import { connect } from "../../../commons/container/utils/loop";
import { ROUTES } from "../../../commons/routes";
import Badge from "../../components/Badge/Badge";
import Button from "../../components/Button/Button";
import CenteredContent from "../../components/CenteredContent/CenteredContent";
import DescriptionList from "../../components/DescriptionList/DescriptionList";
import FlexLayout from "../../components/FlexLayout/FlexLayout";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/Image/Image";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import Note from "../../components/Note/Note";
import Paragraph from "../../components/Paragraph/Paragraph";
import PriceInfo from "../../components/PriceInfo/PriceInfo";
import RadioField from "../../components/RadioField/RadioField";
import SelectCtaButton from "../../components/SelectField/SelectCtaButton";
import SelectField from "../../components/SelectField/SelectField";
import SelectMenuItem from "../../components/SelectField/SelectMenuItem";
import SizingStatusIcon from "../../components/SizingStatusIcon/SizingStatusIcon";
import Tooltip, { TooltipPosition } from "../../components/Tooltip/Tooltip";
import { clearActiveFilter, getActiveFilterValues } from "../../libs/filters";
import useFetchData from "../../libs/hooks/use-fetch-data";
import { useMediaQuery } from "../../libs/hooks/use-media-query";
import useOnMount from "../../libs/hooks/use-on-mount";
import useSelector from "../../libs/hooks/use-selector";
import type { UseVeloconnectEndpointReturn } from "../../libs/hooks/use-veloconnect-endpoint";
import useVeloconnectProductAvailabilities, {
  UseVeloconnectProductAvailabilitiesReturn
} from "../../libs/hooks/use-veloconnect-product-availabilities";
import useAvailableBrands from "../../libs/queries/use-available-brands";
import * as selectors from "../../libs/selectors";
import { State } from "../../reducers";
import { useTrackingContext } from "../utils/tracking-context";
import { useVeloconnectEndpointContext } from "../utils/veloconnect-endpoint-context";

import {
  getFilterSpecKeys,
  getVariantsTableSpecKeys,
  getVisibleVariantsTableSpecKeys,
  useBodySizing
} from "./SharedVariantsContentPartial";
import VariantInformationBoxPartial from "./VariantInformationBoxPartial";

const useAvailabilityStatus = (
  hasInternetConnectivity: boolean,
  product: Product,
  veloconnectEndpoint: UseVeloconnectEndpointReturn,
  veloconnectProductAvailabilities: UseVeloconnectProductAvailabilitiesReturn
) => {
  const { mixpanel } = useTrackingContext();

  useOnMount(() => {
    mixpanel?.trackProductVariantsOpened(product);

    return () => {
      mixpanel?.trackProductVariantsClosed(product);
    };
  });

  const { getAvailableBrand } = useAvailableBrands();

  const isBicycle = isProductOfType(product, ProductType.Bicycle);
  const brandKey = getSingleSpecValue(ProductSpecKey.BrandKey, product);
  const variants = getStandaloneProductVariants(product);

  const isWawiConfigured = useSelector(selectors.selectIsWawiConfigured);

  const availabilityPrioritizationOptions = useSelector(
    state => selectors.selectInitializedSettings(state).customization.availabilityPrioritizationOptions
  );

  const isAvailabilityActive = getAvailableBrand(brandKey)?.availabilityPossible ?? false;

  // TODO: BCD-6796 Enable wawi for all products
  const isWawiActive = isBicycle && isAvailabilityActive && isWawiConfigured;

  // TODO: BCD-6796 Enable wawi for all products
  const wawiProductAvailabilities = useFetchData(() => getWawiProductAvailabilty(product), [product], {
    isEnabled: isBicycle && isWawiActive
  });

  const wawiError: WawiErrorType | undefined = !wawiProductAvailabilities.isLoading
    ? wawiProductAvailabilities.error && wawiProductAvailabilities.error instanceof WawiError
      ? wawiProductAvailabilities.error.wawiError
      : undefined
    : undefined;

  const wawiAvailabilities =
    !wawiProductAvailabilities.isLoading && !wawiError && wawiProductAvailabilities.data?.variants;

  const getWawiAvailabilityByVariant = (variantId: ProductId): WawiProductAvailabilityVariant | undefined =>
    (wawiAvailabilities || []).find(variant => variant.variantId === variantId);

  const hasAtLeastOneValidWawiVariant = variants.some(variant => {
    const wawiDetails = getWawiAvailabilityByVariant(variant[ProductKey.VariantId]);
    return wawiDetails && !wawiDetails.itemUnknown;
  });

  // Expression to dertermine if the availability status column should be shown
  const showAvailabilityStatus =
    (hasInternetConnectivity &&
      !!veloconnectEndpoint.endpoint?.isConfigured &&
      !veloconnectEndpoint.error &&
      veloconnectEndpoint.isImportReady &&
      !veloconnectEndpoint.isImportOutdated) ||
    (isWawiActive && (wawiProductAvailabilities.isLoading || hasAtLeastOneValidWawiVariant));

  const getAvailabilityState = (variantId: ProductId): AvailabilityState => {
    const wawiAvailability = getWawiAvailabilityByVariant(variantId);
    const veloconnectAvailability = veloconnectProductAvailabilities.getAvailabilityByVariant(variantId);

    return getAvailabilityStateLib(wawiAvailability, veloconnectAvailability, availabilityPrioritizationOptions);
  };

  return {
    // Veloconnect
    veloconnectError: veloconnectEndpoint.error || veloconnectProductAvailabilities.error,
    veloconnectEndpoint,
    veloconnectProductAvailabilities,

    // Wawi
    wawiError,
    wawiProductAvailabilities,
    isWawiActive,

    // Veloconnect & Wawi
    showAvailabilityStatus,
    getAvailabilityState
  };
};

type VariantCustomizationLinkMap = {
  [variantId: string]: string | undefined;
};

type VariantCustomizationLink = {
  link: string;
  priority: number;
};

const useVariantCustomizationLink = (productAvailabilities: UseVeloconnectProductAvailabilitiesReturn) => {
  const variantCustomizationLinksMap: VariantCustomizationLinkMap = React.useMemo(() => {
    if (!productAvailabilities.availabilities) {
      return {};
    }

    return productAvailabilities.availabilities.reduce<VariantCustomizationLinkMap>((acc, productAvailability) => {
      // Gather all customization links from the availabilities
      const customizationLinks: VariantCustomizationLink[] = productAvailability.availabilities
        .map(variantAvailability => {
          const customizationLink = variantAvailability.itemInformation?.bikeCenterDeepLink;
          return !!customizationLink ? { link: customizationLink, priority: variantAvailability.priority } : undefined;
        })
        .filter(byDefined);

      if (customizationLinks.length > 0) {
        // Only add the link with the highest priority to the link map
        const highestPriorityLink = customizationLinks.reduce((highest, current) => {
          return current.priority > highest.priority ? current : highest;
        }).link;

        return {
          ...acc,
          [productAvailability.variantId]: highestPriorityLink
        };
      }

      return acc;
    }, {});
  }, [productAvailabilities.availabilities]);

  const showTableHeader = React.useMemo(() => {
    return Object.values(variantCustomizationLinksMap).length > 0;
  }, [variantCustomizationLinksMap]);

  const isLoading = productAvailabilities.isLoading;

  const showButton = !productAvailabilities.isLoading && showTableHeader;

  const get = React.useCallback(
    (variantId: string): string | undefined => variantCustomizationLinksMap[variantId],
    [variantCustomizationLinksMap]
  );

  return {
    isLoading,
    showButton,
    showTableHeader,
    get
  };
};

const mapStateToProps = (state: State) => ({
  activeFilters: state.session.activeFilters,
  bodySizingEnabled: selectors.selectIsBodySizingEnabled(state),
  bikeFrameSizeCalculatorEnabled:
    selectors.selectInitializedSettings(state).toolSettings.bikeFrameSizeCalculator.meta.toolVisible ?? false,
  currency: selectors.selectInitializedSettings(state).currency,
  assortmentPriceSettings: selectors.selectAssortmentPriceSettings(state)
});

const mapDispatchToProps = {
  openBodySizingModal: modalActions.openBodySizingModal,
  setActiveFilters: sessionActions.setActiveFilters,
  onPush: push
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface OuterProps<Type extends ProductType = ProductType> {
  variantId?: ProductId;
  product: Product<Type>;
  hasInternetConnectivity?: boolean;
  noSeparationShadow?: boolean;
  noTopSpacing?: boolean;
  openFrameSizeCalculatorTool?: () => void;
  onCloseBodySizingModal?: () => void;
}

type Props<Type extends ProductType> = ConnectedProps<typeof connector> & OuterProps<Type>;

const VariantsContentPartial = <Type extends ProductType = ProductType>({
  variantId,
  product,
  currency,
  activeFilters,
  bodySizingEnabled,
  bikeFrameSizeCalculatorEnabled,
  assortmentPriceSettings,
  openBodySizingModal,
  onCloseBodySizingModal,
  openFrameSizeCalculatorTool,
  setActiveFilters,
  hasInternetConnectivity = false,
  noSeparationShadow = false,
  noTopSpacing = false,
  onPush
}: Props<Type>) => {
  const {
    t,
    i18n,
    i18n: { language: locale }
  } = useTranslation(["commons"]);

  const productType = product[ProductKey.ProductType];
  const specConfig = getSpecConfig(productType);

  const veloconnectEndpoint = useVeloconnectEndpointContext();

  const specFormatter = getSpecFormatter(productType, specConfig.specDefinitions, i18n);
  const breakpoints = useMediaQuery();

  const isMobileBuild = envelope.isMobileBuild;
  const isSmallViewport = !breakpoints.l;

  const selectedRowRef = React.useRef<HTMLTableRowElement>(null);
  const [selectFieldHasBeenOpened, setSelectFieldHasBeenOpened] = React.useState(false);

  // TODO: This partial should be rebuilt to be more generic
  // `as` is used to cast the type to ProductType.Bicycle, because the current implementation is only used for bicycles
  const [activeBodySizingFilterValues] =
    getActiveFilterValues<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
      activeFilters as ActiveFilters<ProductType.Bicycle>,
      CustomBicycleFilterKey.Sizing
    ) ?? [];
  const bodySizing = useBodySizing(product, activeFilters ?? {}, hasInternetConnectivity);

  const veloconnectProductAvailabilities = useVeloconnectProductAvailabilities(product);
  const variantCustomizationLink = useVariantCustomizationLink(veloconnectProductAvailabilities);

  const availabilityStatus = useAvailabilityStatus(
    hasInternetConnectivity,
    product,
    veloconnectEndpoint,
    veloconnectProductAvailabilities
  );

  const variantsTableSpecKeys = React.useMemo(
    () => getVariantsTableSpecKeys(product, specConfig, availabilityStatus.showAvailabilityStatus),
    [product, specConfig, availabilityStatus.showAvailabilityStatus]
  );
  const variantFilterSpecKeys = React.useMemo(
    () =>
      getFilterSpecKeys(product, specConfig, variantsTableSpecKeys, {
        bodySizingEnabled,
        hasInternetConnectivity,
        bikeFrameSizeCalculatorEnabled
      }),
    [
      product,
      specConfig,
      variantsTableSpecKeys,
      bodySizingEnabled,
      hasInternetConnectivity,
      bikeFrameSizeCalculatorEnabled
    ]
  );

  // The filters here do not support multi-selection. Therefore we bascically cannot map the global filters to the variant filters.
  const [localActiveFilters, setLocalActiveFilters] = React.useState<ProductFilter[]>([]);

  const visibleVariantsTableSpecKeys = getVisibleVariantsTableSpecKeys(
    variantsTableSpecKeys,
    variantFilterSpecKeys,
    localActiveFilters
  );

  const { isLoading: isLoadingProduct, data: filteredProduct } = useFetchData(
    () => getProduct<Type>(product[ProductKey.ProductId], currency, assortmentPriceSettings, localActiveFilters),
    [product, currency, localActiveFilters, activeFilters, bodySizingEnabled, hasInternetConnectivity]
  );

  const allVariants = getStandaloneProductVariants(product);
  const filteredVariants = filteredProduct ? getStandaloneProductVariants(filteredProduct) : [];
  const [selectedVariantId, setSelectedVariantId] = React.useState<ProductId>(
    () => variantId || allVariants[0][ProductKey.VariantId]
  );

  // If the selected variant is filtered, select the first non-filtered variant.
  React.useEffect(() => {
    if (
      filteredVariants.length > 0 &&
      filteredVariants.find(variant => variant[ProductKey.VariantId] === selectedVariantId) === undefined
    ) {
      setSelectedVariantId(filteredVariants[0][ProductKey.VariantId]);
    }
    // This code should only trigger when filteredProduct results change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProduct]);

  // If body sizing changes select the first variant which fits.
  React.useEffect(() => {
    if (bodySizing.result && filteredVariants.length > 0 && bodySizing.result[selectedVariantId] !== SizingResult.Fit) {
      const firstFittingVariantId = Object.entries(bodySizing.result).find(
        ([, result]) => result === SizingResult.Fit
      )?.[0];
      if (firstFittingVariantId !== undefined) {
        setSelectedVariantId(firstFittingVariantId);
      }
    }
    // This code should only trigger when bodySizing results change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodySizing.result]);

  const selectedVariant = filteredVariants.find(variant => variant[ProductKey.VariantId] === selectedVariantId);

  React.useEffect(() => {
    if (selectedRowRef.current) {
      selectedRowRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [selectedVariantId, isLoadingProduct]);

  const renderVariantFilters = () => {
    return variantFilterSpecKeys.map(specKey => {
      const isFrameSizeKey = specKey === BicycleSpecKey.FrameSize;
      const isSmartfitVariantFilter = isFrameSizeKey && bodySizing.isPossible && !!activeBodySizingFilterValues;

      const selectMenuItems = getFilterValuesForVariantsBySpecKey(filteredVariants, specKey)
        .map(item => ({
          ...item,
          formattedValue: specFormatter.formatVariantValue(specKey, item.variant)
        }))
        .filter(({ formattedValue }) => formattedValue !== undefined)
        .map(({ value, variant }, index) => (
          <SelectMenuItem key={index} value={value} label={specFormatter.formatVariantValue(specKey, variant)} />
        ));

      const handleResetSelection = () => {
        if (isSmartfitVariantFilter) {
          setActiveFilters(
            clearActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
              activeFilters ?? {},
              CustomBicycleFilterKey.Sizing
            )
          );
        } else {
          setLocalActiveFilters(current => current.filter(filter => filter.key !== specKey));
        }
      };

      // TODO BCD-6031: type any because of types in SelectField
      const handleSelectFieldChange = (value: any) => {
        if (isFrameSizeKey && bodySizing.isPossible && value !== "smartfit") {
          setActiveFilters(
            clearActiveFilter<ProductType.Bicycle, CustomBicycleFilterKey.Sizing>(
              activeFilters ?? {},
              CustomBicycleFilterKey.Sizing
            )
          );
        }

        // Remove the old filter value and add the new one
        setLocalActiveFilters(current => [
          ...current.filter(filter => filter.key !== specKey),
          { key: specKey.toString(), value }
        ]);
      };

      const renderFrameSizeFieldIcon = () => {
        if (activeBodySizingFilterValues) {
          const toolTipContent = (
            <>
              <DescriptionList>
                <dt>
                  {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.bodyHeight")}
                  :
                </dt>
                <dd>{formatUnit(activeBodySizingFilterValues.bodyHeight, locale, { unit: "centimeter" })}</dd>
                <dt>
                  {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.inseam")}:
                </dt>
                <dd>{formatUnit(activeBodySizingFilterValues.inseam, locale, { unit: "centimeter" })}</dd>
                <dt>
                  {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterActivatedTooltip.armLength")}
                </dt>
                <dd>{formatUnit(activeBodySizingFilterValues.armLength, locale, { unit: "centimeter" })}</dd>
              </DescriptionList>
              <br />
              <img src={smartfitLogoImage} />
            </>
          );

          return (
            <Tooltip closeOnOutsideClick preferredPosition={TooltipPosition.Bottom} content={toolTipContent}>
              {({ open }) => (
                <span
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    open();
                  }}
                >
                  <Icon block source={IconMediumFrameSize} />
                </span>
              )}
            </Tooltip>
          );
        } else if (selectFieldHasBeenOpened) {
          return <Icon block source={IconMediumFrameSize} />;
        } else {
          return (
            <Tooltip
              openOnMountDelay={3000}
              openOnMount
              preferredPosition={TooltipPosition.Bottom}
              content={t("commons:variantsContentPartialProductDetails.filter.bodySizingEnabledFilterTooltip")}
            >
              <Icon block source={IconMediumFrameSize} />
            </Tooltip>
          );
        }
      };

      const renderCtaButton = (closePortal: () => void) => (
        <>
          {bodySizing.isPossible && (
            <SelectCtaButton
              key="bodySizingCta"
              disabled={!bodySizing.isAvailable}
              onClick={() => {
                openBodySizingModal({ onClose: onCloseBodySizingModal, variant: "productDetail" });
                handleResetSelection();
              }}
            >
              {t("commons:variantsContentPartialProductDetails.smartfitCtaButton")}
              <br />
              <img src={smartfitLogoInvertImage} />
            </SelectCtaButton>
          )}
          {!isMobileBuild &&
            openFrameSizeCalculatorTool &&
            bikeFrameSizeCalculatorEnabled &&
            !bodySizing.isAvailable && (
              <SelectCtaButton
                key="frameSizeCalculatorCta"
                onClick={() => {
                  closePortal();
                  openFrameSizeCalculatorTool();
                }}
              >
                {t("commons:variantsContentPartialProductDetails.openFrameSizeCalculatorCtaButton")}
              </SelectCtaButton>
            )}
        </>
      );

      const selectFieldName = specFormatter.formatLabel(specKey);
      const selectFieldValue = isSmartfitVariantFilter
        ? "smartfit"
        : localActiveFilters.find(filter => filter.key === specKey)?.value;

      const showUnknownSizingError = bodySizing.isPossible && !!bodySizing.error && !bodySizing.isLoading;
      const showNoFittingDataSizingError =
        bodySizing.isPossible && !bodySizing.error && !bodySizing.isAvailable && !bodySizing.isLoading;

      return (
        <div key={String(specKey)}>
          <SelectField
            name={selectFieldName}
            showName
            placeholder={t("commons:variantsContentPartialProductDetails.filter.selectFieldNamePlaceholder", {
              fieldName: selectFieldName
            })}
            value={selectFieldValue}
            onChange={handleSelectFieldChange}
            withResetSelectionButton
            onResetSelection={handleResetSelection}
            minContentWidthForMenuItems
            ctaButtons={isFrameSizeKey ? renderCtaButton : undefined}
            icon={isFrameSizeKey && bodySizing.isPossible && renderFrameSizeFieldIcon()}
            onClick={() => setSelectFieldHasBeenOpened(true)}
          >
            {compact([
              isFrameSizeKey && bodySizing.isPossible ? (
                <SelectMenuItem
                  key="__smartfit__"
                  value="smartfit"
                  label={<Image originalSize src={smartfitLogoImage} />}
                  classNames={["u-display-none"]}
                />
              ) : null,
              ...selectMenuItems
            ])}
          </SelectField>
          {isFrameSizeKey && showUnknownSizingError && (
            <Paragraph kind="support" size="s">
              {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterUnknownError")}
            </Paragraph>
          )}
          {isFrameSizeKey && showNoFittingDataSizingError && (
            <Paragraph kind="support" size="s">
              {t("commons:variantsContentPartialProductDetails.filter.bodySizingFilterNoFittingDataError")}
            </Paragraph>
          )}
        </div>
      );
    });
  };

  const renderAvailabilityStatusCell = (variant: StandaloneProductVariant) => {
    const variantId = variant[ProductKey.VariantId];

    if (
      availabilityStatus.wawiProductAvailabilities.isLoading ||
      availabilityStatus.veloconnectProductAvailabilities.isLoading
    ) {
      return <LoadingIndicator size="s" label={t("commons:variantsContentPartialProductDetails.loadingLabel")} />;
    }

    const availabilityState = availabilityStatus.getAvailabilityState(variantId);

    return (
      <Availability
        kind={availabilityState.type}
        {...availabilityState}
        label={t(`commons:variantsContentPartialProductDetails.availabilityStatus.${availabilityState.status}`, {
          expectedDeliveryDate: availabilityState.expectedDeliveryDate
            ? formatDate(availabilityState.expectedDeliveryDate, locale)
            : "",
          warehouseLocation: availabilityState.warehouseLocation
        })}
      />
    );
  };

  const renderVariantCustomizationButton = (variantId: ProductId) => {
    if (variantCustomizationLink.isLoading) {
      return <LoadingIndicator size="s" label={t("commons:variantsContentPartialProductDetails.loadingLabel")} />;
    }

    const link = variantCustomizationLink.get(variantId);

    if (link) {
      // BrowserLocationState ist commented out, because we aren't allowed to import kiosk types to commons (restricted imports)
      // This is just temporary, until we have a shared type for kiosk and mobile for the BrowserLocationState
      const state /*: BrowserLocationState */ = {
        href: link
      };

      const isSelected = selectedVariantId === variantId;

      return (
        <Button
          size="s"
          disabled={!hasInternetConnectivity}
          variant={isSelected ? "accent" : "primary"}
          onClick={() => {
            if (isMobileBuild) {
              window.open(link);
            } else {
              onPush(ROUTES.BROWSER.INDEX, state);
            }
          }}
        >
          {t("commons:variantsContentPartialProductDetails.personalize")}
        </Button>
      );
    }
  };

  const renderCellValue = (specKey: keyof SpecMap[Type], variant: StandaloneProductVariant<Type>) => {
    if (specKey === ProductSpecKey.Price) {
      const hasDiscount = variant[ProductSpecKey.DiscountPercentage] !== null;
      const originalPrice =
        hasDiscount && assortmentPriceSettings.showOriginalPrices
          ? specFormatter.formatVariantValue(ProductSpecKey.OriginalPrice, variant)
          : null;
      const saleBadge =
        hasDiscount && assortmentPriceSettings.showSaleBadge ? (
          <Badge
            variant="neutral"
            size="inherit"
            label={
              assortmentPriceSettings.showSaleBadge === "percentage"
                ? specFormatter.formatVariantValue(ProductSpecKey.DiscountPercentage, variant)
                : t("commons:defaults.sale")
            }
          />
        ) : null;
      return (
        <PriceInfo
          price={specFormatter.formatVariantValue(ProductSpecKey.Price, variant)}
          originalPrice={originalPrice}
          badge={saleBadge}
        />
      );
    }

    // We keep || for the case of empty strings
    return (
      specFormatter.formatVariantValue(specKey, variant) ||
      t("commons:variantsContentPartialProductDetails.table.noInformationLabel")
    );
  };

  const renderTableHeader = () => {
    return compact([
      <TableHeaderCell key="__selection__">
        {t("commons:variantsContentPartialProductDetails.table.selectionLabel")}
      </TableHeaderCell>,
      ...visibleVariantsTableSpecKeys.map(specKey => (
        <TableHeaderCell key={String(specKey)}>{specFormatter.formatLabel(specKey)}</TableHeaderCell>
      )),
      availabilityStatus.showAvailabilityStatus && (
        <TableHeaderCell key="__status__">
          {t("commons:variantsContentPartialProductDetails.table.statusLabel")}
        </TableHeaderCell>
      ),
      variantCustomizationLink.showTableHeader && (
        <TableHeaderCell key="__personalize__">
          {t("commons:variantsContentPartialProductDetails.personalize")}
        </TableHeaderCell>
      )
    ]);
  };

  const renderTableRow = (variant: StandaloneProductVariant<Type>) => {
    const variantId = variant[ProductKey.VariantId];

    return (
      <TableRow
        key={variantId}
        ref={variantId === selectedVariantId ? selectedRowRef : undefined}
        selected={variantId === selectedVariantId}
        onClick={() => setSelectedVariantId(variantId)}
      >
        <TableCell verticalPadding="s">
          <RadioField checked={variantId === selectedVariantId} />
        </TableCell>
        {visibleVariantsTableSpecKeys.map(specKey => (
          <TableCell
            icon={
              specKey === BicycleSpecKey.FrameSize &&
              activeBodySizingFilterValues &&
              bodySizing.result && (
                <SizingStatusIcon
                  errorTooltipContent={
                    <>{t("commons:variantsContentPartialProductDetails.table.sizingNotFoundErrorTooltip")}</>
                  }
                  status={bodySizing.result[variantId] === SizingResult.Fit ? "fit" : "error"}
                />
              )
            }
            key={String(specKey)}
            verticalPadding="s"
          >
            {renderCellValue(specKey, variant)}
          </TableCell>
        ))}
        {availabilityStatus.showAvailabilityStatus && (
          <TableCell verticalPadding="s">{renderAvailabilityStatusCell(variant)}</TableCell>
        )}
        {variantCustomizationLink.showButton && (
          <TableCell verticalPadding="s">{renderVariantCustomizationButton(variantId)}</TableCell>
        )}
      </TableRow>
    );
  };

  const headline = availabilityStatus.veloconnectEndpoint
    ? (availabilityStatus.veloconnectEndpoint.endpoint?.isConfigurable &&
        availabilityStatus.veloconnectEndpoint.shouldShowNotification) ||
      (availabilityStatus.veloconnectEndpoint.isImportReady &&
        availabilityStatus.veloconnectEndpoint.endpoint?.isConfigured) ||
      availabilityStatus.isWawiActive
      ? t("commons:variantsContentPartialProductDetails.variantsAvailabilityHeadline")
      : t("commons:variantsContentPartialProductDetails.variantsHeadline")
    : "";

  return (
    <AvailabilityLayout
      headline={headline}
      noSeparationShadow={noSeparationShadow}
      noTopSpacing={noTopSpacing}
      filters={renderVariantFilters()}
      selectedVariant={
        selectedVariant && (
          <VariantInformationBoxPartial
            hasInternetConnectivity={hasInternetConnectivity}
            product={product}
            productVariant={selectedVariant}
            isVeloconnectActive={!!availabilityStatus.veloconnectEndpoint.endpoint?.isConfigured}
            veloconnectAvailabilities={
              !!availabilityStatus.veloconnectEndpoint.endpoint?.isConfigured
                ? availabilityStatus.veloconnectProductAvailabilities
                : undefined
            }
            veloconnectError={availabilityStatus.veloconnectError}
            isWawiActive={availabilityStatus.isWawiActive}
            wawiProductAvailabilities={
              availabilityStatus.isWawiActive ? availabilityStatus.wawiProductAvailabilities : undefined
            }
            wawiError={availabilityStatus.wawiError}
          />
        )
      }
    >
      {isLoadingProduct ? (
        <LoadingIndicator size="s" label={t("commons:variantsContentPartialProductDetails.loadingLabel")} />
      ) : (
        <>
          {filteredProduct ? (
            <Table dynamicSize inContent header={renderTableHeader()}>
              {filteredVariants.map(variant => renderTableRow(variant))}
            </Table>
          ) : (
            <CenteredContent
              classNames={isMobileBuild ? ["u-padding-xxxl"] : ["u-padding-xxxxl", "u-padding-top-base"]}
            >
              <Note
                spacing={isSmallViewport ? "s" : "l"}
                icon={<Icon source={IconMediumFrameSize} size={isSmallViewport ? "base" : "l"} />}
                footer={
                  <FlexLayout direction="column" gap="xs">
                    {!isMobileBuild && (
                      <Button block size="s" variant="accent" kind="solid" onClick={openFrameSizeCalculatorTool}>
                        {t("commons:variantsContentPartialProductDetails.startFrameSizeCalculatorCtaButton")}
                      </Button>
                    )}
                    {activeBodySizingFilterValues && (
                      <Button
                        block
                        size="s"
                        variant={isMobileBuild ? "accent" : "primary"}
                        kind="solid"
                        onClick={() => setActiveFilters(omit(activeFilters, CustomBicycleFilterKey.Sizing))}
                      >
                        {t("commons:variantsContentPartialProductDetails.filter.resetFrameSizeFilterLabel")}
                      </Button>
                    )}
                  </FlexLayout>
                }
              >
                <Paragraph kind="support" size={isSmallViewport ? "xs" : "base"}>
                  <strong>{t("commons:variantsContentPartialProductDetails.table.emptyHeadline")}</strong>
                  <br />
                  {isSmallViewport
                    ? t("commons:variantsContentPartialProductDetails.table.emptyShort")
                    : t("commons:variantsContentPartialProductDetails.table.empty")}
                </Paragraph>
              </Note>
            </CenteredContent>
          )}
        </>
      )}
    </AvailabilityLayout>
  );
};

export default connector(VariantsContentPartial);
