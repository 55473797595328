import { isInSeason, isLeapYear } from "../libs/wallpapers";

import { CustomerGroupId } from "./customer-groups";

export enum WallpaperId {
  Abstract0 = "abstract0",
  Abstract2 = "abstract2",
  Landscape0 = "landscape0",
  Landscape1 = "landscape1",
  Lifestyle0 = "lifestyle0",
  Lifestyle1 = "lifestyle1",
  Lifestyle2 = "lifestyle2",
  Lifestyle3 = "lifestyle3",
  Lifestyle4 = "lifestyle4",
  Lifestyle5 = "lifestyle5",
  Lifestyle6 = "lifestyle6",
  Lifestyle7 = "lifestyle7",
  Mountain0 = "mountain0",
  Mountain1 = "mountain1",
  Mountain2 = "mountain2",
  Mountain3 = "mountain3",
  Mountain4 = "mountain4",
  Mountain5 = "mountain5",
  Mountain6 = "mountain6",
  Road0 = "road0",
  Road1 = "road1",
  Road2 = "road2",
  Technical0 = "technical0",
  Xmas0 = "xmas0"
}

export type Day = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31; // prettier-ignore
export enum Month { Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec } // prettier-ignore
export type WallpaperSeason = "spring" | "summer" | "fall" | "winter" | "xmas";
export type WallpaperSeasonPeriod = [Month, Day, Month, Day];
export type WallpaperSeasonConfig = { period: WallpaperSeasonPeriod; wallpaperId: WallpaperId };
export type WallpaperSeasonsConfig = Record<WallpaperSeason, WallpaperSeasonConfig>;

const spring: WallpaperSeasonPeriod = [Month.Mar, 1, Month.May, 31];
const summer: WallpaperSeasonPeriod = [Month.Jun, 1, Month.Sep, 30];
const fall: WallpaperSeasonPeriod = [Month.Oct, 1, Month.Nov, 30];
const xmas: WallpaperSeasonPeriod = [Month.Dec, 1, Month.Jan, 5];
const winter: WallpaperSeasonPeriod = [Month.Jan, 6, Month.Feb, isLeapYear() ? 29 : 28];

const BICYCLE_DEFAULT_WALLPAPERS: WallpaperSeasonsConfig = {
  spring: { period: spring, wallpaperId: WallpaperId.Lifestyle5 },
  summer: { period: summer, wallpaperId: WallpaperId.Lifestyle3 },
  fall: { period: fall, wallpaperId: WallpaperId.Mountain2 },
  xmas: { period: xmas, wallpaperId: WallpaperId.Xmas0 },
  winter: { period: winter, wallpaperId: WallpaperId.Mountain1 }
};

const MOTORCYCLE_DEFAULT_WALLPAPERS: WallpaperSeasonsConfig = {
  spring: { period: spring, wallpaperId: WallpaperId.Abstract0 },
  summer: { period: summer, wallpaperId: WallpaperId.Abstract0 },
  fall: { period: fall, wallpaperId: WallpaperId.Abstract0 },
  xmas: { period: xmas, wallpaperId: WallpaperId.Xmas0 },
  winter: { period: winter, wallpaperId: WallpaperId.Abstract0 }
};

const WALLPAPERS: Record<CustomerGroupId, WallpaperSeasonsConfig> = {
  bicycle: BICYCLE_DEFAULT_WALLPAPERS,
  "bicycle-bico": BICYCLE_DEFAULT_WALLPAPERS,
  "bicycle-orbea": BICYCLE_DEFAULT_WALLPAPERS,
  motorcycle: MOTORCYCLE_DEFAULT_WALLPAPERS
};

const FALLBACK_WALLPAPERS: Record<CustomerGroupId, WallpaperId> = {
  bicycle: WallpaperId.Lifestyle3,
  "bicycle-bico": WallpaperId.Lifestyle3,
  "bicycle-orbea": WallpaperId.Lifestyle3,
  motorcycle: WallpaperId.Abstract0
};

export const getDefaultWallpaper = (customerGroup: CustomerGroupId, now: Date = new Date()): WallpaperId => {
  const activeSeason = Object.entries(WALLPAPERS[customerGroup]).find(([_, config]) => isInSeason(now, config.period));

  return activeSeason ? activeSeason[1].wallpaperId : FALLBACK_WALLPAPERS[customerGroup];
};
