import React from "react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";

interface Props extends Component {
  children: React.ReactNode;
  itemsRight?: React.ReactNode[];
  alignment?: "right-bottom" | "right-center";
  isAsideOpen?: boolean;
}

const ProductDetailsContentLayout = ({
  classNames = [],
  children,
  itemsRight,
  alignment = "right-bottom",
  isAsideOpen = false
}: Props) => {
  return (
    <div
      className={cn(
        "ProductDetailsContentLayout",
        [{ [`alignment-${alignment}`]: alignment, isAsideOpen }],
        classNames
      )}
    >
      <div className="ProductDetailsContentLayout__content">{children}</div>
      <div className="ProductDetailsContentLayout__columnRight">{itemsRight}</div>
    </div>
  );
};

export default ProductDetailsContentLayout;
