import React from "react";
import { AnimatePresence, AnimationProps, motion, Variant as MotionVariant } from "motion/react";

import { Component } from "../../../../commons/types/component";
import cn from "../../libs/class-name";
import Image from "../Image/Image";

const Variant = {
  AsideClosed: "AsideClosed",
  AsideOpen: "AsideOpen"
} as const;

type VariantKey = (typeof Variant)[keyof typeof Variant];
type Variants = Partial<Record<VariantKey, MotionVariant>>;

const transition: AnimationProps["transition"] = {
  type: "tween",
  ease: "easeInOut"
};

const ProductImage = ({
  action,
  imageUrl,
  isProductContentAsideOpen,
  onClick
}: {
  action?: React.ReactNode;
  imageUrl: string;
  isProductContentAsideOpen: boolean;
  onClick?: () => void;
}) => {
  const imageVariants: Variants = {
    AsideClosed: { x: 0, scale: 1 },
    AsideOpen: { x: "25vw", scale: 0.75 }
  };

  const actionVariants: Variants = {
    AsideClosed: { opacity: 1 },
    AsideOpen: { opacity: 0 }
  };

  return (
    <motion.div
      className="ProductDetailsImage__image"
      variants={imageVariants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={isProductContentAsideOpen ? Variant.AsideOpen : Variant.AsideClosed}
    >
      <Image position="center" fill src={imageUrl} timeout={30_000} onClick={onClick} />

      <AnimatePresence>
        {!isProductContentAsideOpen ? (
          <motion.div
            className="ProductDetailsImage__action"
            variants={actionVariants}
            transition={transition}
            initial={Variant.AsideOpen}
            animate={Variant.AsideClosed}
            exit={Variant.AsideOpen}
          >
            {action}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
};

const ProductTitle = ({
  children,
  isProductContentAsideOpen
}: {
  children: React.ReactNode;
  isProductContentAsideOpen: boolean;
}) => {
  const variants: Variants = {
    AsideClosed: { x: 0, opacity: 1 },
    AsideOpen: { x: "10vw", opacity: 0 }
  };

  return (
    <motion.div
      className="ProductDetailsImage__title"
      variants={variants}
      transition={transition}
      initial={Variant.AsideClosed}
      animate={isProductContentAsideOpen ? Variant.AsideOpen : Variant.AsideClosed}
    >
      {children}
    </motion.div>
  );
};

interface Props extends Component {
  imageUrl: string;
  productTitle: React.ReactNode;
  isProductContentAsideOpen?: boolean;
  action?: React.ReactNode;
  onClick?: () => void;
}

const ProductDetailsImage = ({
  classNames,
  isProductContentAsideOpen = false,
  imageUrl,
  productTitle,
  action,
  onClick
}: Props) => (
  <div className={cn("ProductDetailsImage", [{ isProductContentAsideOpen }], classNames)}>
    <ProductImage
      imageUrl={imageUrl}
      action={action}
      isProductContentAsideOpen={isProductContentAsideOpen}
      onClick={onClick}
    />
    <ProductTitle isProductContentAsideOpen={isProductContentAsideOpen}>{productTitle}</ProductTitle>
  </div>
);

export default ProductDetailsImage;
