import i18n from "../../../commons/i18n-config";
import { ProductDetailsAdvertiser } from "../../../commons/types/advertisers";

import { SharedConfig } from "./config-types";

const sharedConfig: SharedConfig = {
  shared: {
    // NOTE: Keep these in sync with src/client/commons/styles/mixins/_media.scss
    mediaQueryBreakpoints: {
      "s-only": "(max-width: 1023px)",
      m: "(min-width: 1024px)",
      "m-only": "(min-width: 1024px) and (max-width: 1365px)",
      l: "(min-width: 1366px)",
      "l-only": "(min-width: 1366px) and (max-width: 1919px)",
      xl: "(min-width: 1920px)"
    },
    advertisers: {
      productDetailsWidget: [ProductDetailsAdvertiser.Alteos, ProductDetailsAdvertiser.ZegPlusGarantie],
      productPrintDataSheet: [ProductDetailsAdvertiser.Alteos]
    },
    personalCodeLength: 6,
    printMaxCopies: 10,
    doubleClickTimeout: 250,
    minLoadingTimeout: 300,
    checkServiceAvailability: {
      retryCount: 60,
      retryDelay: 1000
    },

    // Filter config
    filter: {
      filterFetchDelay: 500,
      sizingLimit: 2000
    },

    // i18n config
    i18n,

    transitionsDurations: {
      fast: 0.15,
      default: 0.3,
      slow: 0.8,
      crawl: 1.5
    }
  }
};

export default sharedConfig;
