import { WallpaperSeasonPeriod } from "../config/wallpapers";

export const isInSeason = (now: Date, [startMonth, startDay, endMonth, endDay]: WallpaperSeasonPeriod): boolean => {
  const month = now.getMonth();
  const day = now.getDate();

  // Handle same month case first
  if (startMonth === endMonth) {
    return month === startMonth && day >= startDay && day <= endDay;
  }

  // If end month is less than start month, we're crossing a year boundary
  if (endMonth < startMonth) {
    // We're in the start month (e.g., December) and after or on the start day
    if (month === startMonth && day >= startDay) {
      return true;
    }

    // We're in the end month (e.g., January) and before or on the end day
    if (month === endMonth && day <= endDay) {
      return true;
    }

    // We're in any month between the start and end
    // (considering the year boundary)
    if (month > startMonth || month < endMonth) {
      return true;
    }

    return false;
  }

  // No year boundary crossing (e.g., Mar-May)
  return (
    (month === startMonth && day >= startDay) || // First month after start day
    (month === endMonth && day <= endDay) || // Last month before end day
    (month > startMonth && month < endMonth)
  );
};

export const isLeapYear = (date: Date = new Date()): boolean => {
  const year = date.getFullYear();
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
};
