import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { SpecFormatter } from "../../../../commons/libs/formatters";
import { getMaxDiscountPercentage, getMinOriginalPrice, getMinPrice } from "../../../../commons/libs/specs";
import { Product, ProductSpecKey, ProductType } from "../../../../commons/specs/product";
import Badge from "../../components/Badge/Badge";
import ProductTitle from "../../components/ProductTitle/ProductTitle";
import useSelector from "../../libs/hooks/use-selector";
import { selectAssortmentPriceSettings, selectInitializedSettings } from "../../libs/selectors";

interface Props {
  product?: Product;
  specFormatter?: SpecFormatter<ProductType>;
  align?: ComponentProps<typeof ProductTitle>["align"];
  wrap?: ComponentProps<typeof ProductTitle>["wrap"];
}

const ProductDetailsTitlePartial = ({ product, specFormatter, align, wrap }: Props) => {
  const settings = useSelector(state => selectInitializedSettings(state));
  const assortmentPriceSettings = useSelector(state => selectAssortmentPriceSettings(state));

  const { t } = useTranslation(["commons"]);

  if (!product || !specFormatter) {
    return undefined;
  }

  return (
    <ProductTitle
      align={align}
      wrap={wrap}
      name={specFormatter.formatProductValue(ProductSpecKey.ModelName, product) ?? ""}
      year={specFormatter.formatProductValue(ProductSpecKey.ModelYear, product) ?? ""}
      price={
        settings.customization.showPrices ? specFormatter.formatProductValue(ProductSpecKey.Price, product) : undefined
      }
      originalPrice={
        (getMinPrice(product) ?? 0) < (getMinOriginalPrice(product) ?? 0) &&
        getMaxDiscountPercentage(product) &&
        assortmentPriceSettings.showOriginalPrices
          ? specFormatter.formatProductValue(ProductSpecKey.OriginalPrice, product)
          : undefined
      }
      badge={
        getMaxDiscountPercentage(product) ? (
          assortmentPriceSettings.showSaleBadge === "word" ? (
            <Badge label={t("commons:defaults.sale")} size="s" />
          ) : assortmentPriceSettings.showSaleBadge === "percentage" ? (
            <Badge label={specFormatter.formatProductValue(ProductSpecKey.DiscountPercentage, product)} size="s" />
          ) : undefined
        ) : undefined
      }
    />
  );
};

export default ProductDetailsTitlePartial;
